import React from 'react';
import Header from 'src/components/editor/Header'
import LinkedInImport from 'src/components/editor/LinkedInImport'
import PersonalDetails from 'src/components/editor/PersonalDetails';
import ProfessionalSummary from 'src/components/editor/ProfessionalSummary';
import WebsitesAndSocial from 'src/components/editor/WebsitesAndSocial';
import WebsitesAndSocialContext from 'src/contexts/WebsitesAndSocialContext';
import {EmploymentHistory} from 'src/components/editor/EmploymentHistory'
import {Education} from 'src/components/editor/Education'
import {Skills} from 'src/components/editor/Skills'
import {reorderedArray, arrayWithoutItemAtIndex, arrayWithUpdatedObject, pseudoUuid} from 'src/utilities/functions'
import 'src/styles/editor-shared.css'

const Editor = ({ profile, setProfile, updateRemoteProfile, onClickLinkedInImport}) => {
  // - METADATA / PROFILE NAME
  const updateName = (newValue) => {
    setProfile((profile) => ({
        ...profile,
        metadata: {
          ...profile.metadata,
          name: newValue,
        }
    }))
    updateRemoteProfile({'metadata.name': newValue})
  }

  // - PERSONAL DETAILS
  const updatePersonalDetails = (key, value) => {
    setProfile((profile) => ({
        ...profile,
        personalDetails: {
          ...profile.personalDetails,
          [key]: value
        }
    }))
    updateRemoteProfile({[`personalDetails.${key}`]: value})
  }

  // - PROFESSIONAL SUMMARY
  const updateProfessionalSummary = (value) => {
    setProfile((profile) => ({
      ...profile,
      professionalSummary: value
    }))
    updateRemoteProfile({professionalSummary: value})
  }

  // - WEBSITES AND SOCIAL
  const addToWebsitesAndSocial = () => {
    const newItem = {
                      uuid: pseudoUuid(),
                      link: '',
                      label: ''
                    }
    setProfile((profile) => {
      const updatedValue = [...profile.websitesAndSocial, newItem]
      updateRemoteProfile({websitesAndSocial: updatedValue})
      return {
        ...profile,
        websitesAndSocial: updatedValue
      }
    })

    return newItem
  }

  const updateWebsitesAndSocial = (index, key, newValue) => {
    setProfile((profile) => {
      const updatedValue = arrayWithUpdatedObject(profile.websitesAndSocial, index, key, newValue)
      updateRemoteProfile({websitesAndSocial: updatedValue})
      return {
        ...profile,
        websitesAndSocial: updatedValue,
      }
    })
  }

  const deleteFromWebsitesAndSocial = (index) => {
    setProfile((profile) => {
      const updatedValue = arrayWithoutItemAtIndex(profile.websitesAndSocial, index)
      updateRemoteProfile({websitesAndSocial: updatedValue})
      return {
        ...profile,
        websitesAndSocial: updatedValue
      }
    })
  }

  const reorderWebsitesAndSocial = (index, newIndex) => {
    setProfile((profile) => {
      const updatedValue = reorderedArray(profile.websitesAndSocial, index, newIndex)
      updateRemoteProfile({websitesAndSocial: updatedValue})
      return {
        ...profile,
        websitesAndSocial: updatedValue
      }
    })
  }

  // - EMPLOYMENT HISTORY
  const addToEmploymentHistory = () => {
    const newItem = {
                      uuid: pseudoUuid(),
                      jobTitle: '',
                      employer: '',
                      city: '',
                      description: '',
                      startDate: new Date(),
                      endDate: new Date(),
                      isCurrent: false,
                    }
    setProfile((profile) => {
      const updatedValue = [...profile.employmentHistory, newItem]
      updateRemoteProfile({employmentHistory: updatedValue})
      return {
        ...profile,
        employmentHistory: updatedValue
      }
    })
    return newItem
  }

  const updateEmploymentHistory = (index, key, newValue) => {
    setProfile((profile) => {
      const updatedValue = arrayWithUpdatedObject(profile.employmentHistory, index, key, newValue)
      updateRemoteProfile({employmentHistory: updatedValue})
      return {
        ...profile,
        employmentHistory: updatedValue
      }
    })
  }

  const deleteFromEmploymentHistory = (index) => {
    setProfile((profile) => {
      const updatedValue = arrayWithoutItemAtIndex(profile.employmentHistory, index)
      updateRemoteProfile({employmentHistory: updatedValue})
      return {
        ...profile,
        employmentHistory: updatedValue
      }
    })
  }

  const reorderEmploymentHistory = (index, newIndex) => {
    setProfile((profile) => {
      const updatedValue = reorderedArray(profile.employmentHistory, index, newIndex)
      updateRemoteProfile({employmentHistory: updatedValue})
      return {
        ...profile,
        employmentHistory: updatedValue
      }
    })
  }

  // - EDUCATION
  const addToEducation = () => {
    const newItem = {
                      uuid: pseudoUuid(),
                      school: '',
                      degree: '',
                      city: '',
                      description: '',
                      startDate: new Date(),
                      endDate: new Date(),
                      isCurrent: false,
                    }
    setProfile((profile) => {
      const updatedValue = [...profile.education, newItem]
      updateRemoteProfile({education: updatedValue})
      return {
        ...profile,
        education: updatedValue
      }
    })
    return newItem
  }

  const updateEducation = (index, key, newValue) => {
    setProfile((profile) => {
      const updatedValue = arrayWithUpdatedObject(profile.education, index, key, newValue)
      updateRemoteProfile({education: updatedValue})
      return {
        ...profile,
        education: updatedValue
      }
    })
  }

  const deleteFromEducation = (index) => {
    setProfile((profile) => {
      const updatedValue = arrayWithoutItemAtIndex(profile.education, index)
      updateRemoteProfile({education: updatedValue})
      return {
        ...profile,
        education: updatedValue
      }
    })
  }

  const reorderEducation = (index, newIndex) => {
    setProfile((profile) => {
      const updatedValue = reorderedArray(profile.education, index, newIndex)
      updateRemoteProfile({education: updatedValue})
      return {
        ...profile,
        education: updatedValue
      }
    })
  }

  // - SKILLS
  // adds skill and uses optional skillName if given
  const addToSkills = (skillName) => {

    const newItem = {
                      uuid: pseudoUuid(),
                      skill: skillName || '',
                      level: 0,
                    }
    setProfile((profile) => {
      const updatedValue = [...profile.skills, newItem]
      updateRemoteProfile({skills: updatedValue})
      return {
        ...profile,
        skills: updatedValue
      }
    })

    return newItem
  }

  const updateSkills = (index, key, newValue) => {
    setProfile((profile) => {
      const updatedValue = arrayWithUpdatedObject(profile.skills, index, key, newValue)
      updateRemoteProfile({skills: updatedValue})
      return {
        ...profile,
        skills: updatedValue
      }
    })
  }

  const deleteFromSkills = (index) => {
    setProfile((profile) => {
      const updatedValue = arrayWithoutItemAtIndex(profile.skills, index)
      updateRemoteProfile({skills: updatedValue})
      return {
        ...profile,
        skills: updatedValue
      }
    })
  }

  const reorderSkills = (index, newIndex) => {
    setProfile((profile) => {
      const updatedValue = reorderedArray(profile.skills, index, newIndex)
      updateRemoteProfile({skills: updatedValue})
      return {
        ...profile,
        skills: updatedValue
      }
    })
  }

  const {
    personalDetails,
    professionalSummary,
    websitesAndSocial,
    employmentHistory,
    education,
    skills,
    metadata: { name },
  } = profile

  const resumeJobTitle = personalDetails.jobTitle // for employment history

  return (
    <div className="editor-container">
      <Header {...{name, updateName}} />
      <LinkedInImport onClick={onClickLinkedInImport}/>
      <PersonalDetails personalDetails={personalDetails} updatePersonalDetails={updatePersonalDetails}/>
      <ProfessionalSummary {...{professionalSummary, updateProfessionalSummary, resumeJobTitle, }}/>
      <WebsitesAndSocialContext.Provider value={{websitesAndSocial, addToWebsitesAndSocial, updateWebsitesAndSocial, deleteFromWebsitesAndSocial, reorderWebsitesAndSocial}}>
        <WebsitesAndSocial />
      </WebsitesAndSocialContext.Provider>
      <EmploymentHistory {...{employmentHistory, addToEmploymentHistory, updateEmploymentHistory, deleteFromEmploymentHistory, reorderEmploymentHistory, resumeJobTitle,}}/>
      <Education {...{education, addToEducation, updateEducation, deleteFromEducation, reorderEducation}}/>
      <Skills {...{skills, addToSkills, updateSkills, deleteFromSkills, reorderSkills, resumeJobTitle}} />
    </div>
  );
};

export default Editor;
