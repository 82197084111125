import { navigate } from 'gatsby'
import { saveAs } from 'file-saver'
import { createCustomerPortalSession } from 'src/utilities/http-requests'
import { renderResumeToPDF } from 'src/utilities/render-pdf'
import { UserSubscriptionStatusEnum, getUserSubscriptionStatus } from 'src/utilities/user-subscription-status'

const downloadPDF = ({
  blobURL,
  profile
}) => {
  const name = profile.metadata.name || 'My Resume'
  const fileName = `${name}.pdf`
  if (blobURL) {
    saveAs(blobURL, fileName)
  } else {
    // if we don't have blobURL (eg downloading
    // from somewhere we are not already rendering
    // like index vs show)
    // then render pdf first
    renderResumeToPDF({ profile })
    .then(({blobURL}) => {
      saveAs(blobURL, fileName)
    })
    .catch(error => {
      console.error('error rendering or downloading pdf', error)
    })
  }
}

const attemptDownloadPDF = ({
  profile,
  userSubscription,
  blobURL,
  willAttemptCreateCustomerPortalSession,
  didSuccessfullyCreateCustomerPortalSession,
  didFailToCreateCustomerPortalSession,
  returnURL,
  didDownloadPDF,
}) => {
  const resumeId = profile.id

  const status = getUserSubscriptionStatus({userSubscription})

  switch (status) {
    case UserSubscriptionStatusEnum.valid:
      downloadPDF({blobURL, profile})
      didDownloadPDF && didDownloadPDF()
      break
    case UserSubscriptionStatusEnum.lapsed:
      willAttemptCreateCustomerPortalSession()
      const { customerId } = userSubscription
      createCustomerPortalSession({
        customerId,
        returnURL,
      })
      .then(session => {
        didSuccessfullyCreateCustomerPortalSession(session)
      })
      .catch(error => {
        didFailToCreateCustomerPortalSession(error)
      })
      break
    case UserSubscriptionStatusEnum.new:
      navigate(`/plans?successURL=${process.env.BASE_URL}/resumes/${resumeId}`)
      break
    default:
      console.error('attemptDownloadPDF: unexpected user subscription status')
      break
  }
}

export { attemptDownloadPDF }
