import React, {useEffect} from "react"
import Modal from 'react-modal'
import Spinner from 'react-bootstrap/spinner'

const MessageModal = ({isOpen, onRequestClose, onClickCTA, imageSrc, title, body, CTAText, variant, isCTALoading,}) => {
  let CTAButtonVariant = 'btn-primary'
  if (variant == 'danger') {
    CTAButtonVariant = 'btn-danger'
  }
  const closeButtonStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
  }
  const modalBodyStyle = {
    maxWidth: 500,
  }
  // https://github.com/reactjs/react-modal/blob/master/docs/styles/index.md
  const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 1050,
    },
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      borderRadius: '2%',
      padding: 50,
    }
  }
  const image = imageSrc ? <img src={imageSrc} width={200}/> : null
  const CTAContent = isCTALoading ? <Spinner animation="border" variant='light'/> : CTAText

  // get rid of warning Warning: react-modal: App element is not defined. Please use `Modal.setAppElement(el)` or set `appElement={el}`. This is needed so screen readers don't see main content when modal is opened. It is not recommended, but you can opt-out by setting `ariaHideApp={false}`.
  // https://github.com/reactjs/react-modal/issues/133
  useEffect(() => {
    Modal.setAppElement('body')
  })

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel='Message Modal'
    >
      <div
        className='modal-body text-center'
        style={modalBodyStyle}
      >
        <button
          className='close'
          style={closeButtonStyle}
          onClick={onRequestClose}
        >
          x
        </button>
        { image }
        <h2 className='font-weight-bold text-center mb-1'>{title}</h2>
        {body}
        <button
          className={`btn ${CTAButtonVariant} btn-block mt-5`}
          onClick={onClickCTA}
          disabled={isCTALoading}
        >
          {CTAContent}
        </button>
      </div>
    </Modal>
    )
}

export default MessageModal
