import React, { useState, useEffect, useRef} from 'react';
import Spinner from 'react-bootstrap/spinner'
import { trackPage, trackEvent } from 'src/utilities/analytics'
import withSize from 'src/utilities/withSize'
import { useMediaQuery } from 'react-responsive'
import Editor from 'src/components/resumes/editor'
import Previewer from 'src/components/resumes/previewer'
import LinkedInImportModal from 'src/components/LinkedInImportModal'
import ShareModal from 'src/components/resumes/ShareModal'
import { isProfileEmpty } from 'src/utilities/profile'
import { setResume as setRemoteResume } from 'src/utilities/firestore'
import PreviewerSpinner from 'src/components/resumes/PreviewerSpinner'
import Paginator from 'src/components/shared/Paginator'
import Layout4Blocks from 'src/images/icons/layout-4-blocks.svg'
import FileIcon from 'src/images/icons/files-file.svg'
import Button from 'src/components/shared/Button'

const pageName = 'Editor Previewer'

const Container = ({children}) => {
  const containerStyle = {
  }
  return (
    <div style={containerStyle}>
      {children}
    </div>
    )
}

const PreviewAndDownloadButton = ({onClick}) => {
  // this button is aware of whether the user is scrolling up or down
  // and expands or contracts accordingly.
  const [isExpanded, setIsExpanded] = useState(true)
  // handle scroll direction
  const lastYOffset = useRef(window.pageYOffset || document.documentElement.scrollTop)
  const handleScroll = (event) => {
    const yOffset = window.pageYOffset || document.documentElement.scrollTop
    if (yOffset > lastYOffset.current) {
      // contract button when scrolling down
      setIsExpanded(false)
    } else {
      // expand button when scrolling up
      setIsExpanded(true)
    }
    lastYOffset.current = yOffset
  }
  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (window) {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])
  const buttonStyle = {
    position: 'fixed',
    bottom: 15,
    right: 15,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'row',
    transitionProperty: 'width',
    transitionDuration: '.3s',
    width: isExpanded ? 229 : 66,
  }
  const textStyle = {
    marginLeft: 10,
    transition: 'height 0s, opacity .3s',
    opacity: isExpanded ? 1 : 0,
    // keep text from taking up calculated space in button when it's supposed to be hidden
    position: isExpanded ? 'static' : 'absolute',
    // keep text from wrapping and blowing up button height while
    // button width is still transitioning outward
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }
  return (
    <button
    style={buttonStyle}
    onClick={onClick}
    className='btn btn-primary btn-pill'
    >
      <FileIcon fill='white'/>
      <div style={textStyle}>Preview & Download</div>
    </button>
    )
}

const EditorContainer = ({profile, setProfile, updateRemoteProfile, style, showPreviewAndDownloadButton, toggleView, onClickLinkedInImport}) => {
  const containerStyle = {
    width: '50%', // only xl width
    ...style,
  }

  return (
    <div style={containerStyle}>
      <Editor {...{profile, setProfile, updateRemoteProfile, onClickLinkedInImport}}/>
      {
        showPreviewAndDownloadButton &&
        <PreviewAndDownloadButton
          onClick={toggleView}
        />
      }
    </div>
    )
}

const ButtonContainer = ({width, onClickSelectTemplate, onClickDownloadPDF, isDownloadButtonLoading, onClickShare}) => {
  const containerStyle = {
    width,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0px',
  }
  const selectTemplateButtonStyle = {
    display: 'flex',
    flexDirection: 'row',
  }
  const buttonTextStyle = {
    marginLeft: 10,
  }
  const rightContainerStyle = {}
  const shareButtonStyle = {
    marginRight: 10,
  }

  const downloadButtonContent = isDownloadButtonLoading ? <Spinner animation="border" variant='light'/> : 'Download PDF'

  return (
    <div style={containerStyle}>
      <button style={selectTemplateButtonStyle} className="btn btn-light-soft btn-sm" onClick={onClickSelectTemplate}>
        <Layout4Blocks fill='white'/>
        <div style={buttonTextStyle}>
          Select Template
        </div>
      </button>
      <div style={ rightContainerStyle }>
        <Button
          size='sm'
          style={ shareButtonStyle }
          onClick={onClickShare}
        >
          Share
        </Button>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => {
            onClickDownloadPDF({pageName})
          }}
          disabled={isDownloadButtonLoading}
        >
          {downloadButtonContent}
        </button>
      </div>
    </div>
    )
}

const PreviewerContainer = ({profile, toggleView, onClickDownloadPDF, size, style, setCurrentBlobURL, isDownloadButtonLoading, updatePreviewURL, onClickShare, }) => {
  const {
    width,
    height
  } = size
  const [pageNumber, setPageNumber] = useState(1)
  const [numPages, setNumPages] = useState(1) // numPages gets set in pdf viewer

  // loading state and callback used to tie together
  // state of pdf rendering in previewer and the spinner state we show here
  const [isLoading, setIsLoading] = useState(true)
  const loadingCallback = (isLoading) => {
    setIsLoading(isLoading)
  }

  const containerStyle = {
    ...style,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
  const previewerStyle = {}

  // size pdf page proportionally to this container element
  let pageWidth = width * .8
  // pdf width / height ratio = .707
  // if element dimensions get too wide (width to height ratio is too high)
  // need to determine page width that will still allow full document to fit
  const widthToHeightRatio = width / height
  if (widthToHeightRatio > .707) {
    // element is proportionally wider than pdf
    // height is limiting dimension, use height to figure out a pageWidth
    pageWidth = height * .707 * .8
  }

  const previousButtonEnabled = pageNumber > 1
  const nextButtonEnabled = pageNumber < numPages

  const onClickPreviousPage = () => {
    setPageNumber(pageNumber - 1)
  }
  const onClickNextPage = () => {
    setPageNumber(pageNumber + 1)
  }
  const paginatorStyle = {
    position: 'fixed',
    bottom: 24,
  }

  return (
    <div style={containerStyle}>
      <PreviewerSpinner width={pageWidth} {...{isLoading}}/>
      <Previewer {...{profile, pageWidth, pageNumber, setNumPages, loadingCallback, setCurrentBlobURL, updatePreviewURL,}} style={previewerStyle}/>
      <ButtonContainer width={Math.max(pageWidth, 450)} onClickSelectTemplate={toggleView} {...{isDownloadButtonLoading, onClickDownloadPDF, onClickShare,}}/>
      <Paginator {...{pageNumber, numPages, onClickPreviousPage, onClickNextPage, previousButtonEnabled, nextButtonEnabled}} style={paginatorStyle}/>
    </div>
    )
}

const PreviewerContainerWithSize = withSize(PreviewerContainer)

const EditorPreviewer = ({ profile, setProfile, updateRemoteProfile, toggleView, onClickDownloadPDF, setCurrentBlobURL, isDownloadButtonLoading, updatePreviewURL, hasProfileBeenFetched}) => {

  const [isLinkedInImportModalOpen, setIsLinkedInImportModalOpen] = useState(false)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  // <1280px width, editor is full width of screen and 'Preview and Download' button floats bottom right.
  // <768px width, editor goes to 1 column layout instead of 2

  const isXLWidth = useMediaQuery({ query: '(min-width: 1280px)' })
  const editorContainerStyle = {
    width: isXLWidth ? '50%' : '100%',
  }
  // sizeme uses passed in style to render placeholder:
  // moving styles that apply to placeholder
  // to a prop that gets passed in helps with crazy ass visual artifacts
  const previewerContainerStyle = {
    backgroundColor: 'rgb(122, 133, 153)',
    width: '50%',
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
  }

  useEffect(() => {
    trackPage(pageName)
  }, [])

  useEffect(() => {
    // show linked in import modal
    // if the profile has been fetched and is empty
    if (hasProfileBeenFetched && isProfileEmpty(profile)) {
      setIsLinkedInImportModalOpen(true)
    }
  }, [hasProfileBeenFetched])

  const onClickLinkedInImport = () => {
    setIsLinkedInImportModalOpen(true)
    trackEvent('clicked_linked_in_import', {
      page: pageName,
      isProfileEmpty: isProfileEmpty(profile),
    })
  }

  const onClickShare = () => {
    setIsShareModalOpen(true)
  }

  return (
    <Container>
      <LinkedInImportModal
        isOpen={isLinkedInImportModalOpen}
        onRequestClose={() => setIsLinkedInImportModalOpen(false)}
        fetchedProfileHandler={(fetchedProfile) => {
          // merge name and theme from old profile into fetched profile
          const mergedProfile = {
            // ownerUID, id, anything i might forget
            ...profile,
            // important profile data
            ...fetchedProfile,
            // re-write metadata since proxycurl / empty profiles have metadata
            metadata: {
              ...profile.metadata,
            }
          }
          // set remote profile
          setRemoteResume({
            resumeId: profile.id,
            resume: mergedProfile,
          })
          // set local profile
          setProfile(mergedProfile)
          setIsLinkedInImportModalOpen(false)

          // analytics
          const linkedIn = mergedProfile.websitesAndSocial.find(e => e.label == 'LinkedIn')
          trackEvent('linked_in_profile_import_success', {
            page: pageName,
            url: linkedIn,
          })
        }}
        isProfileEmpty={isProfileEmpty(profile)}
      />
      <ShareModal
        isOpen={isShareModalOpen}
        onRequestClose={() => setIsShareModalOpen(false)}
        {...{
          profile,
        }}
      />
      <EditorContainer
        {...{profile, setProfile, updateRemoteProfile, toggleView, onClickLinkedInImport}}
        style={editorContainerStyle}
        showPreviewAndDownloadButton={!isXLWidth}
      />
      {
        isXLWidth && <PreviewerContainerWithSize {...{profile, toggleView, onClickDownloadPDF, setCurrentBlobURL, isDownloadButtonLoading, updatePreviewURL, onClickShare,}} style={previewerContainerStyle}/>
      }
    </Container>
    )
};

export default EditorPreviewer;
