import React, { useState, useEffect, useRef } from 'react';
import {navigate, Link} from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import SEO from "src/components/seo"
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import SupportHelmet from 'src/components/SupportHelmet'
import { identify, trackEvent, summarizeProfile } from 'src/utilities/analytics'
import { useURLParamToTrackEvent } from 'src/utilities/hooks'
import { attemptDownloadPDF } from 'src/utilities/download-pdf'
import {makeSafeProfile} from 'src/utilities/safe-profile'
import { getUserSubscription } from 'src/utilities/http-requests'
import { getUserSubscriptionStatus } from 'src/utilities/user-subscription-status'
import {themeForKey} from 'src/themes/themes'
import ReviewModal from 'src/components/ReviewModal'
import EditorPreviewer from 'src/components/resumes/EditorPreviewer'
import ThemePreviewer from 'src/components/resumes/ThemePreviewer'
import MediaQueryContext from 'src/contexts/MediaQueryContext'
import { getResume, doesReviewExist, updateResume, } from 'src/utilities/firestore'
import getFirebase from 'src/utilities/firebase'
// import { noahFranklinProfile } from 'src/dummy-data'

const extractResumeId = (pathname) => {
  const components = pathname.split('/')
  const filtered = components.filter((c) => c.length > 0)
  return filtered[1]
}

const Container = ({children}) => {
  const containerStyle = {
  }
  return (
    <div style={containerStyle}>
      {children}
    </div>
    )
}

const SupportHelmetContainer = () => {
  // hide zendesk widget on this page on mobile since it overlaps the preview and download button
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  if (isMobile) {
    return null
  }

  return <SupportHelmet/>
}

const ResumesShow = ({ location }) => {
  const resumeId = extractResumeId(location.pathname)

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isPhone = useMediaQuery({ query: '(max-width: 576px)' })

  const [currentUser, setCurrentUser] = useState(null)

  if (!resumeId) {
    navigate('/resumes')
  }

  // check for stripe_success=true url param and fire stripe conversion event if present
  // this is to track stripe conversions using success_url
  useURLParamToTrackEvent({
    urlParamKey: 'stripe_success',
    urlParamKeys: ['revenue'],
    eventProperties: {
      page: 'Editor Previewer',
    },
  })

  // - DOWNLOAD PDF
  const [isDownloadButtonLoading, setIsDownloadButtonLoading] = useState(false)
  const onClickDownloadPDF = ({pageName}) => {
    const willAttemptCreateCustomerPortalSession = () => {
      setIsDownloadButtonLoading(true)
    }
    const didSuccessfullyCreateCustomerPortalSession = (session) => {
      navigate(session.url)
      setIsDownloadButtonLoading(false)
    }
    const didFailToCreateCustomerPortalSession = (error) => {
      console.error(error)
      setIsDownloadButtonLoading(false)
    }
    attemptDownloadPDF({
      profile,
      userSubscription: userSubscription.current,
      blobURL: currentBlobURL.current,
      willAttemptCreateCustomerPortalSession,
      didSuccessfullyCreateCustomerPortalSession,
      didFailToCreateCustomerPortalSession,
      returnURL: `${process.env.BASE_URL}/resumes/${resumeId}`,
      didDownloadPDF: () => {
        // after download, if they have not yet submitted a review, ask for one
        setShouldShowReviewModal(shouldSolicitBetaReview)
      },
    })
    const profileSummary = summarizeProfile(profile)
    const {
      charCount,
      wordCount,
      sectionsWithContent,
    } = profileSummary
    trackEvent('clicked_download_pdf', {
      page: pageName,
      subscription_status: getUserSubscriptionStatus({userSubscription: userSubscription.current}),
      profileSummary,
      charCount,
      wordCount,
      sectionsWithContent,
      sectionsWithContentCount: sectionsWithContent.length,
      theme: selectedThemeKey,
    })
  }
  // - TOGGLE VIEW
  // view toggles between 'editor' and 'theme'
  const [currentView, setCurrentView] = useState('editor')
  // const [currentView, setCurrentView] = useState('theme') // TODO put this back
  const toggleView = () => {
    setCurrentView((currentView) => {
      return currentView === 'editor' ? 'theme' : 'editor'
    })
  }
  const CurrentViewClass = (currentView === 'editor') ? EditorPreviewer : ThemePreviewer

  // - PROFILE
  const [profile, setProfile] = useState(makeSafeProfile({}))

  // load fake data, for development and generating images:
  // const [profile, setProfile] = useState(makeSafeProfile(noahFranklinProfile))

  // print current profile to json to save as json file. use only in development and generating images
  // console.log(JSON.stringify(profile))

  // - THEME
  const selectedThemeKey = profile.metadata.theme
  const theme = themeForKey(selectedThemeKey)
  // - USER SUBSCRIPTION / ACCESS
  const userSubscription = useRef(null)
  const setUserSubscription = (newValue) => {
    userSubscription.current = newValue
  }
  const currentBlobURL = useRef(null)
  const setCurrentBlobURL = (newValue) => {
    currentBlobURL.current = newValue
  }
  const checkAndUpdateSubscriptionStatus = () => {
    if (!firebase || !firebase.auth().currentUser) {
      return
    }
    const userUID = firebase.auth().currentUser.uid
    getUserSubscription({userUID})
    .then(userSubscription => {
      setUserSubscription(userSubscription)
    })
    .catch(error => {
      console.error(error)
    })
  }

  const [hasProfileBeenFetched, setHasProfileBeenFetched] = useState(false)
  const firebase = getFirebase();
  useEffect(() => {
    // when firebase is available, pull resume down
    // and set local state to it.
    // TODO: show error if can't get resume. consider
    // someone shares a link and the other person doesn't have permissions

    // can comment this out when loading fake static data
    if (!firebase) return;
    getResume({resumeId})
    .then(resume => {
      setProfile(resume)
      setHasProfileBeenFetched(true)
    })
    .catch(error => {
      if (error.code === 'permission-denied') {
        navigate('/resumes')
      }
      console.error(`could not get resume with id: ${resumeId}`, error)
    })

  }, [firebase]);

  // receives object of updates to send to firestore
  // eg { 'personalDetails.name': 'Frank' }
  const updateRemoteProfile = (updates, bypassUpdatedAt) => {
    if (resumeId) {
      updateResume({
        resumeId,
        updates,
        bypassUpdatedAt,
      })
      .catch(error => {
        console.error('error updating resume', error)
      })
    }
  }
  const setSelectedTheme = (newTheme) => {
    setProfile(profile => {
      updateRemoteProfile({'metadata.theme': newTheme})
      return {
        ...profile,
        metadata: {
          ...profile.metadata,
          theme: newTheme,
        }
      }
    })
  }

  const updatePreviewURL = ({previewURL}) => {
    updateRemoteProfile({
      metadata: {
        ...profile.metadata,
        previewURL
      }
    }, true)
  }

  // - USER SUBSCRIPTION / ACCESS
  // live connection to user subscription could be useful
  // although it would mean extra requests
  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        checkAndUpdateSubscriptionStatus()
        identify(user)
      }
      setCurrentUser(user)
    });
  }, [firebase])

  const [shouldShowReviewModal, setShouldShowReviewModal] = useState(false)
  const onRequestCloseReviewModal = () => {
    setShouldShowReviewModal(false)
  }
  const didSubmitReview = () => {
    setShouldShowReviewModal(false)
  }

  const reviewName = 'beta'
  const [shouldSolicitBetaReview, setShouldSolicitBetaReview] = useState(false)
  useEffect(() => {
    if (!firebase) return;
    if (!currentUser) return
    doesReviewExist({ownerUID: currentUser.uid, name: reviewName})
    .then(doesExist => {
      setShouldSolicitBetaReview(!doesExist)
    })
  }, [firebase, currentUser])

  const resumeName = profile.metadata.name

  return (
    <MediaQueryContext.Provider
      value={{
        isPhone,
        isMobile,
      }}
    >
      <SEO title={resumeName} />
      <AnalyticsHelmet/>
      <SupportHelmetContainer/>
      <ReviewModal
        isOpen={shouldShowReviewModal}
        onRequestClose={onRequestCloseReviewModal}
        didSubmitReview={didSubmitReview}
        {...{reviewName, currentUser}}
      />
      <Container>
        <CurrentViewClass
          {...{
            profile, setProfile, updateRemoteProfile, setSelectedTheme,
            theme, toggleView, onClickDownloadPDF, setCurrentBlobURL,
            isDownloadButtonLoading, updatePreviewURL, hasProfileBeenFetched,}}
        />
      </Container>
    </MediaQueryContext.Provider>
    )
};

export default ResumesShow;
