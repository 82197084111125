import React, {useEffect, useState, useRef} from "react"
import Modal from 'react-modal'
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import { trackPage, trackEvent} from 'src/utilities/analytics'
import { createReview } from 'src/utilities/firestore'
import { useMediaQuery } from 'react-responsive'
import Spinner from 'react-bootstrap/spinner'
import Rating from 'react-rating'
import Star from 'src/images/icons/star.svg'
import StarFill from 'src/images/icons/star-fill.svg'

const pageName = 'Review Modal'

const StarRating = ({onChange}) => {
  const isPhone = useMediaQuery({ query: '(max-width: 576px)' })
  const goldYellow = 'rgb(248, 214, 78)'
  const starStyle = {
    marginLeft: isPhone ? 4 : 7.5,
    marginRight: isPhone ? 4 : 7.5,
  }
  const starWidth = isPhone ? 30 : 50
  const emptyStar = <Star
                      width={starWidth}
                      stroke={goldYellow}
                      color={goldYellow}
                      style={starStyle}
                    />
  const filledStar = <StarFill
                      width={starWidth}
                      fill={goldYellow}
                      style={starStyle}
                    />
 return (
  <Rating
    className='mb-5'
    emptySymbol={emptyStar}
    fullSymbol={filledStar}
    onChange={onChange}
  />
  )
}

const ReviewModal = ({isOpen, onRequestClose, didSubmitReview, currentUser, reviewName}) => {
  const [isCTALoading, setIsCTALoading] = useState(false)
  const onClickCTA = () => {
    setIsCTALoading(true)
    const reviewInfo = {
      ratingValue,
      text: textAreaRef.current.value,
      reviewName,
    }
    const createReviewPromise = createReview({
      ownerUID: currentUser.uid,
      name: reviewName,
      ...reviewInfo,
    })
    const trackEventPromise = trackEvent('submit_review', {
      page: pageName,
      ...reviewInfo,
    })
    Promise.all([createReviewPromise, trackEventPromise])
    .then(() => {
      setIsCTALoading(false)
      didSubmitReview && didSubmitReview()
    })
    .catch(error => console.error('createReview && trackEvent error', error))
  }

  // rating value can be selected 1-5
  // 0 is null / unselected
  const [ratingValue, setRatingValue] = useState(0)
  const textAreaRef = useRef(null)

  const closeButtonStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
  }
  const modalBodyStyle = {
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
  // https://github.com/reactjs/react-modal/blob/master/docs/styles/index.md
  const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 1050,
    },
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      borderRadius: '2%',
      padding: 50,
    }
  }
  const CTAText = 'Submit'
  const CTAContent = isCTALoading ? <Spinner animation="border" variant='light'/> : CTAText

  const onChangeRating = (value) => {
    setRatingValue(value)
  }

  const onAfterOpen = () => {
    trackPage(pageName)
  }
  const onAfterClose = () => {
    trackEvent('review_modal_closed', {
      page: pageName,
    })
  }

  // get rid of warning Warning: react-modal: App element is not defined. Please use `Modal.setAppElement(el)` or set `appElement={el}`. This is needed so screen readers don't see main content when modal is opened. It is not recommended, but you can opt-out by setting `ariaHideApp={false}`.
  // https://github.com/reactjs/react-modal/issues/133
  useEffect(() => {
    Modal.setAppElement('body')
  })

  const textAreaStyle = {
    width: '100%',
    margin: 20,
    padding: 20,
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel='Review Modal'
      {...{onAfterOpen, onAfterClose}}
    >
      <div
        className='modal-body text-center'
        style={modalBodyStyle}
      >
        <button
          className='close'
          style={closeButtonStyle}
          onClick={onRequestClose}
        >
          x
        </button>
        <h2 className='font-weight-bold text-center mb-3'>
          How was your experience?
        </h2>
        <StarRating
          onChange={onChangeRating}
        />
        <h4>
          Anything else? We'd love to hear from you so we can make our product better!
        </h4>
        <textarea
          style={textAreaStyle}
          ref={textAreaRef}
        />
        <button
          className={`btn btn-primary btn-block mt-5`}
          onClick={onClickCTA}
          disabled={isCTALoading}
        >
          {CTAContent}
        </button>
      </div>
    </Modal>
    )
}

export default ReviewModal
