import React, {useState, useEffect, useRef} from "react"
import Spinner from 'react-bootstrap/spinner'
import { Link, navigate } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import MessageModal from 'src/components/MessageModal'
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import SupportHelmet from 'src/components/SupportHelmet'
import { identify, trackPage, trackEvent} from 'src/utilities/analytics'
import { useURLParamToTrackEvent } from 'src/utilities/hooks'
import { attemptDownloadPDF } from 'src/utilities/download-pdf'
import { getUserSubscription, createCustomerPortalSession } from 'src/utilities/http-requests'
import { UserSubscriptionStatusEnum, getUserSubscriptionStatus } from 'src/utilities/user-subscription-status'
import getFirebase from 'src/utilities/firebase'
import ShareModal from 'src/components/resumes/ShareModal'
import { createResume, getResumes, copyResume, deleteResume} from 'src/utilities/firestore'
import EditIcon from 'src/images/icons/edit.svg'
import DownloadIcon from 'src/images/icons/download.svg'
import CopyIcon from 'src/images/icons/copy.svg'
import DeleteIcon from 'src/images/icons/trash-2.svg'
import ShareIcon from 'src/images/icons/share.svg'
import SadCatFridge from 'src/images/illustrations/sad-cat-fridge.png'

const pageName = 'My Resumes'
const locationURL = `${process.env.BASE_URL}/resumes`
const navigateToPlans = () => {
  navigate(`/plans?successURL=${process.env.BASE_URL}/resumes`)
}

const Header = ({onClickCreateResume,}) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 40,
  }
  const topContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }

  return (
    <div style={containerStyle}>
      <div style={topContainerStyle}>
        <h2> Your Resumes </h2>
        <button onClick={onClickCreateResume} className='btn btn-primary btn-xs'>+ Create New</button>
      </div>
    </div>

    )
}

const ResumeCardActionItem = ({icon, text, onClick, isLoading}) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 12,
  }
  const imageStyle = {
    width: 24,
    height: 24,
    marginRight: 5,
    color: '#335EEA',
  }
  const Icon = icon
  const content = isLoading ? <Spinner animation="border" variant='primary'/> : [<Icon style={imageStyle}/>, text]
  const className = isLoading ? 'disabled' : 'cursor-pointer'
  return (
    <div
      style={containerStyle}
      {...{
        className,
        onClick,
      }}
    >
      {content}
    </div>
    )
}

const ResumeCard = ({resume, userSubscription, fetchAndSetResumes, showConfirmModalToDeleteResume, onClickCopyResume, cardWidth, isMobile, isPhone, onClickShare}) => {
  const {
    id: resumeId,
    metadata: {
      name,
    },
    updatedAt,
  } = resume
  // should download action item display loading spinner?
  const [isDownloadItemLoading, setIsDownloadItemLoading] = useState(false)

  const containerStyle = {
    width: cardWidth,
    marginBottom: isMobile ? 32 : 42,
    display: 'flex',
    flexDirection: 'row',
    // aligns tops of image and right container on mobile
    // aligns center on larger screens
    alignItems: isMobile ? 'flex-start' : 'center',
  }
  const imageStyle = {
    width: isMobile ? 108 : 278,
    height: isMobile ? 151 : 390,
    marginRight: isMobile ? 20 : 30,
    backgroundColor: 'gray',
  }
  const rightContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 226,
  }
  const titleStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    fontSize: '1.2rem',
    paddingRight: 26,
  }
  const subtitleStyle = {
    marginBottom: 16,
  }
  const dateFormat = new Intl.DateTimeFormat('en', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })

  let updatedAtText = ''
  try {
    // sometimes if you try to load resumes really fast after creating
    // they don't have a updatedAt
    const updatedAtToString = dateFormat.format(updatedAt.toDate())
    updatedAtText = `Updated ${updatedAtToString}`
  } catch (error) {
    console.error('error setting updatedAtText in ResumeCard', error)
  }

  const navigateToShow = () => {
    navigate(`/resumes/${resumeId}`)
  }
  const onClickDownloadPDF = () => {
    const willAttemptCreateCustomerPortalSession = () => {
      setIsDownloadItemLoading(true)
    }
    const didSuccessfullyCreateCustomerPortalSession = (session) => {
      navigate(session.url)
      setIsDownloadItemLoading(false)
    }
    const didFailToCreateCustomerPortalSession = (error) => {
      console.error(error)
      setIsDownloadItemLoading(false)
    }
    attemptDownloadPDF({
      profile: resume,
      userSubscription,
      willAttemptCreateCustomerPortalSession,
      didSuccessfullyCreateCustomerPortalSession,
      didFailToCreateCustomerPortalSession,
      returnURL: locationURL,
    })
    trackEvent('clicked_download_pdf', {
      page: pageName,
      subscription_status: getUserSubscriptionStatus({userSubscription}),
    })
  }

  const onClickDelete = () => {
    showConfirmModalToDeleteResume({resumeId})
  }

  return (
    <div style={containerStyle}>
      <img
        src={resume.metadata.previewURL || null}
        style={imageStyle}
        className='cursor-pointer'
        onClick={navigateToShow}
      />
      <div style={rightContainerStyle}>
        <div style={titleStyle} className='cursor-pointer'>{name}</div>
        <small className='text-muted' style={subtitleStyle}>{updatedAtText}</small>
        <ResumeCardActionItem icon={EditIcon} text='Edit' onClick={navigateToShow}/>
        <ResumeCardActionItem icon={ShareIcon} text='Share' onClick={() => onClickShare(resume)}/>
        <ResumeCardActionItem icon={CopyIcon} text='Copy' onClick={() => onClickCopyResume({resumeId})}/>
        <ResumeCardActionItem
          icon={DownloadIcon}
          text='Download PDF'
          onClick={onClickDownloadPDF}
          isLoading={isDownloadItemLoading}
        />
        <ResumeCardActionItem
          icon={DeleteIcon}
          text='Delete'
          onClick={onClickDelete}
        />
      </div>
    </div>
    )
}

const ResumesList = ({resumes, userSubscription, fetchAndSetResumes, showConfirmModalToDeleteResume, onClickCopyResume, cardWidth, isMobile, isPhone, onClickShare}) => {
  if (!resumes || (resumes && resumes.length < 1)) {
    return null
  }
  const containerStyle = {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  }
  return (
    <div style={containerStyle}>
    {
      resumes.map((resume) => <ResumeCard {...{userSubscription, resume, fetchAndSetResumes, showConfirmModalToDeleteResume, onClickCopyResume, cardWidth, isMobile, isPhone, onClickShare,}} key={resume.id}/>)
    }
    </div>
    )
}

const ResumesIndex = () => {
  const [resumes, setResumes] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [shouldShowResumeLimitModal, setShouldShowResumeLimitModal] = useState(false)
  const [isResumeLimitModalCTALoading, setIsResumeLimitModalCTALoading] = useState(false)
  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false)
  // value is either a profile which should be shared which means the share modal shoudl be open
  // or false which means the modal is closed
  const [profileToShare, setProfileToShare] = useState(false)

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isPhone = useMediaQuery({ query: '(max-width: 576px)' })

  const firebase = getFirebase();
  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      user && checkAndUpdateSubscriptionStatus({userUID: user.uid})
      identify(user)
    });
    }, [firebase]);
  // show all resumes
  useEffect(() => {
    if (!firebase || !currentUser) return;
    fetchAndSetResumes()
  }, [firebase, currentUser]);

  // - USER SUBSCRIPTION / ACCESS
  const userSubscriptionRef = useRef(null)
  const setUserSubscription = (newValue) => {
    userSubscriptionRef.current = newValue
  }
  const userSubscriptionStatusRef = useRef(UserSubscriptionStatusEnum.new)
  const setUserSubscriptionStatus = (newValue) => {
    userSubscriptionStatusRef.current = newValue
  }
  const checkAndUpdateSubscriptionStatus = ({userUID}) => {
    if (!userUID) return
    getUserSubscription({userUID})
    .then(userSubscription => {
      setUserSubscription(userSubscription)
      setUserSubscriptionStatus(getUserSubscriptionStatus({userSubscription}))
    })
    .catch(error => {
      console.error(error)
    })
  }
  // consolidates shared logic between clicking
  // copy resume and create new resume
  // shows relevant messages about your resume limit for your subscription status
  // addResumeAction is a function that is called if you are under your limits
  // eg create or copy resume
  const attemptAddResume = ({
    userSubscriptionStatus,
    addResumeAction,
  }) => {
    const resumesLength = resumes ? resumes.length : 0
    const isValidSubscription = userSubscriptionStatus == UserSubscriptionStatusEnum.valid

    if (isValidSubscription && resumesLength < 10) {
      addResumeAction()
    } else if (isValidSubscription && resumesLength >= 10) {
      setShouldShowResumeLimitModal(true)
    } else if (!isValidSubscription && resumesLength < 1) {
      addResumeAction()
    } else if (!isValidSubscription && resumesLength > 0) {
      setShouldShowResumeLimitModal(true)
    } else {
      console.error('attemptAddResume: unexpected case:', userSubscriptionStatus)
    }
  }

  const onClickCopyResume = ({resumeId}) => {
    const addResumeAction = () => {
      copyResume({
        ownerUID: currentUser.uid,
        resumeId
      })
      .then(docRef => {
        fetchAndSetResumes()
      })
      .catch(error => {
        console.error('copyResume error: ', error)
      })
    }
    attemptAddResume({
      userSubscriptionStatus: userSubscriptionStatusRef.current,
      addResumeAction,
    })
    trackEvent('clicked_copy_resume', {
      page: pageName,
      subscription_status: userSubscriptionStatusRef.current,
    })
  }

  const onClickCreateResume = () => {
    const addResumeAction = () => {
      createResume({ownerUID: currentUser.uid})
      .then(docRef => {
        navigate(`/resumes/${docRef.id}`)
      })
      .catch(error => {
        console.error('createResume error: ', error)
      })
    }
    attemptAddResume({
      userSubscriptionStatus: userSubscriptionStatusRef.current,
      addResumeAction,
    })
    trackEvent('clicked_create_resume', {
      page: pageName,
      subscription_status: userSubscriptionStatusRef.current,
    })
  }

  const fetchAndSetResumes = () => {
    if (!firebase || !currentUser) return
    getResumes({
      ownerUID: currentUser.uid,
      orderBy: ['updatedAt', 'desc'],
    })
    .then(resumes => {
      setResumes(resumes)
    })
    .catch(error => {
      console.error('fetchAndSetResumes error', error)
    })
  }

  const resumeIdToDelete = useRef(null)
  const showConfirmModalToDeleteResume = ({resumeId}) => {
    resumeIdToDelete.current = resumeId
    setShouldShowDeleteModal(true)
  }
  const onClickConfirmDeleteResume = () => {
    setShouldShowDeleteModal(false)
    deleteResume({resumeId: resumeIdToDelete.current})
    .then(() => {
      fetchAndSetResumes()
    })
    .catch(error => console.error('error deleting resume', error))
  }

  const onClickShare = (profile) => {
    setProfileToShare(profile)
  }

  const containerStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: isMobile ? '40px 20px 48px' : '60px 32px',
  }

  const cardWidth = isMobile ? '100%' : 524

  const contentContainerStyle = {
    maxWidth: 1120,
    width: '100%',
  }
  if (!isMobile) {
    contentContainerStyle.width = cardWidth * 2
  }
  // message to show when user needs to upgrade to make more resumes
  // also handles follow up actions, like sending user to plans page
  // or customer billing portal
  const insufficientSubscriptionResumeLimitMessageModalProps = {
    onRequestClose: () => setShouldShowResumeLimitModal(false),
    onClickCTA: () => {
        switch (userSubscriptionStatusRef.current) {
          case UserSubscriptionStatusEnum.new:
            navigateToPlans()
            setShouldShowResumeLimitModal(false)
            break
          case UserSubscriptionStatusEnum.lapsed:
            setIsResumeLimitModalCTALoading(true)
            const { customerId } = userSubscriptionRef.current
            createCustomerPortalSession({
              customerId,
              returnURL: locationURL
            })
            .then(session => {
              navigate(session.url)
              setIsResumeLimitModalCTALoading(false)
            })
            .catch(error => {
              console.error('createCustomerPortalSession error', error)
              setIsResumeLimitModalCTALoading(false)
            })
            break
          default:
            console.error('MessageModal onClickCTA userSubscriptionStatus unexpected case')
        }
        trackEvent('clicked_modal_cta_upgrade', {
                    page: pageName,
                    subscription_status: userSubscriptionStatusRef.current,
                  })
      },
    imageSrc: SadCatFridge,
    title: 'You are over the resume limit',
    body: 'Only one resume is available on the free plan. Upgrade your plan to create up to 10 resumes.',
    CTAText: 'Upgrade',
  }
  // user has too many resumes, even for paid accounts
  const absoluteResumeLimitMessageModalProps = {
    ...insufficientSubscriptionResumeLimitMessageModalProps,
    body: 'You are allowed up to 10 resumes. You can delete old resumes to make room for new ones.\
     If you would like more than 10 resumes, please contact us by emailing support@nextlevelresume.co',
    CTAText: 'OK',
    onClickCTA: () => {
      setShouldShowResumeLimitModal(false)
    },
  }
  const messageModalProps = (resumes && resumes.length >= 10) ? absoluteResumeLimitMessageModalProps : insufficientSubscriptionResumeLimitMessageModalProps

  // check for stripe_success=true url param and fire stripe conversion event if present
  // this is to track stripe conversions using success_url
  useURLParamToTrackEvent({
    urlParamKey: 'stripe_success',
    urlParamKeys: ['revenue'],
    eventProperties: {
      page: pageName,
    },
  })

  useEffect(() => {
    trackPage(pageName)
  }, [])

  return (
    <Layout
      shouldForceLoginModalOpenIfNoCurrentUser={true}
    >
      <MessageModal
        isOpen={shouldShowResumeLimitModal}
        isCTALoading={isResumeLimitModalCTALoading}
        {...messageModalProps}
      />
      <MessageModal
        isOpen={shouldShowDeleteModal}
        onRequestClose={() => setShouldShowDeleteModal(false)}
        onClickCTA={onClickConfirmDeleteResume}
        title='Delete Resume'
        body='Are you sure you want to delete this resume? This cannot be undone.'
        CTAText='Delete'
        variant='danger'
      />
      {
        profileToShare &&
        <ShareModal
          isOpen={profileToShare}
          onRequestClose={() => setProfileToShare(false)}
          profile={profileToShare}
        />
      }
      <SEO title={pageName} />
      <AnalyticsHelmet/>
      <SupportHelmet/>
      <div style={containerStyle}>
        <div style={contentContainerStyle}>
          <Header {...{onClickCreateResume}}/>
          {
            currentUser &&
            <ResumesList userSubscription={userSubscriptionRef.current} {...{fetchAndSetResumes, resumes, showConfirmModalToDeleteResume, onClickCopyResume, cardWidth, isMobile, isPhone, onClickShare}} />
          }
        </div>
      </div>
    </Layout>
  )
}


export default ResumesIndex
