import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Accordion';
import TextInput from 'src/components/shared/TextInput'
import Accordion from 'src/components/shared/Accordion'
import WebsitesAndSocialContext from 'src/contexts/WebsitesAndSocialContext';
import SectionHeader from 'src/components/editor/SectionHeader'

// Label and Link Input Component. Goes inside expanded accordion card.
const LabelAndLink = ({label, link, index, updateWebsitesAndSocial}) => {
  return (
    <Card>
      <TextInput label="Label" value={label} onChange={(event) => updateWebsitesAndSocial(index, 'label', event.target.value)} />
      <TextInput label="Link" value={link} type='url' onChange={(event) => updateWebsitesAndSocial(index, 'link', event.target.value)} />
    </Card>
  )
};

// transforms websites and social data into more generic AccordionCardList format and creates children views
const transformDataToViewModel = (data, updateWebsitesAndSocial) => {
  return data.map((item, index) => {
    return ({
      title: item.label,
      subtitle: item.link,
      child: LabelAndLink({ label: item.label, link: item.link, index, updateWebsitesAndSocial }),
      index: index,
      eventKey: item.uuid
    })
  })
}

const WebsitesAndSocial = ({}) => {
  const { websitesAndSocial, addToWebsitesAndSocial, deleteFromWebsitesAndSocial, reorderWebsitesAndSocial, updateWebsitesAndSocial } = useContext(WebsitesAndSocialContext)
  const addButtonOnClick = () => {
    const uuid = addToWebsitesAndSocial().uuid
    return uuid
  }
  return (
    <section className="w-100 editor-section">
      <Container fluid>
        <SectionHeader title='Websites & Social' description='You can add links to websites you want hiring managers to see! Perhaps It will be  a link to your portfolio, LinkedIn profile, or personal website'/>
        <Accordion
          data={transformDataToViewModel(websitesAndSocial, updateWebsitesAndSocial)}
          addButtonOnClick={addButtonOnClick}
          addButtonText={'+ Add Link'}
          deleteButtonOnClick={deleteFromWebsitesAndSocial}
          reorderElementAfterDrag={reorderWebsitesAndSocial}
          droppableId={'websitesAndSocial'}
        />
      </Container>
    </section>
  );
};

export default WebsitesAndSocial;
