import React, { memo } from 'react';
import Container from 'react-bootstrap/Container';
import RichTextEditor from 'src/components/shared/RichTextEditor';
import SectionHeader from 'src/components/editor/SectionHeader'
import RecommendedContentOverlayButton from 'src/components/recommended-content/RecommendedContentOverlayButton'
import RecommendedProfilePhrasesBox from 'src/components/recommended-content/RecommendedProfilePhrasesBox'
import { trackEvent } from 'src/utilities/analytics'

const pageName = 'Editor Previewer'

const ProfessionalSummary = ({professionalSummary, updateProfessionalSummary, resumeJobTitle,}) => {

  const onClickPhrase = (phrase) => {
    // can receive either phrase or subphrase object
    // if it's subphrase, use text
    let phraseText = phrase.text
    let kind = 'subphrase'
    // if it's a phrase, put together the text
    if (phrase.subphrases) {
      phraseText = phrase.subphrases.map(subphrase => subphrase.text).join(' ')
      kind = 'phrase'
    }

    const currentContent = professionalSummary
    const wrappedPhraseText = `<p>${phraseText}</p>`
    const newContent = currentContent + wrappedPhraseText
    updateProfessionalSummary(newContent)

    trackEvent('recommended_profile_phrase_added', {
      phraseText,
      page: pageName,
      kind,
      objectID: phrase.uuid || phrase.objectID,
    })
  }

  const recommendContentButton = (
    <RecommendedContentOverlayButton
      onClick={() => {
        trackEvent('recommended_profile_phrase_button_clicked', {
          page: pageName,
        })
      }}
    >
      <RecommendedProfilePhrasesBox
        jobTitle={resumeJobTitle}
        {...{
          professionalSummary,
          onClickPhrase,
        }}
      />
    </RecommendedContentOverlayButton>
  )

  return (
    <section className="w-100 editor-section">
      <Container fluid>
        <SectionHeader
          title='Professional Summary'
          description='Include 2-3 clear sentences about your overall experience'
          rightContainerChildren={recommendContentButton}
        />
        <RichTextEditor value={professionalSummary} onChange={updateProfessionalSummary}/>
      </Container>
    </section>
  );
};

export default memo(ProfessionalSummary);
