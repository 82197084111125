import getFirebase from 'src/utilities/firebase'
import 'firebase/storage'

// returns resume preview reference
// https://firebase.google.com/docs/reference/js/firebase.storage.Reference
const getResumePreviewRef = ({resumeId}) => {
  const firebase = getFirebase()
  const currentUserUID = firebase.auth().currentUser && firebase.auth().currentUser.uid
  if (!firebase || !currentUserUID) {
    return
  }

  return firebase.storage().ref().child(`users/${currentUserUID}/resumes/${resumeId}/preview.jpg`)
}

// upload jpg blob as resume preview jpg for resume with id
// returns upload task
// https://firebase.google.com/docs/reference/js/firebase.storage.UploadTask
const uploadResumePreview = ({resumeId, jpgBlob}) => {
  const ref = getResumePreviewRef({resumeId})
  if (!ref) {
    return Promise.reject(new Error('Invalid result from getResumePreviewRef. Verify window is mounted, firebase is available, and currentUser exists.'))
  }
  console.log(`uploading resume preview for resume ${resumeId}`)
  return ref.put(jpgBlob)
}

export { uploadResumePreview }
