import React, {useEffect, useState, useRef} from "react"
import styled from 'styled-components';
import Modal from 'src/components/Modal'
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import { trackPage, trackEvent} from 'src/utilities/analytics'
import TextInput from 'src/components/shared/TextInput'
import Button from 'src/components/shared/Button'
import Toast from 'react-bootstrap/Toast'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
  EmailShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon as RSFacebookIcon,
  LinkedinIcon as RSLinkedInIcon,
  RedditIcon,
  TwitterIcon as RSTwitterIcon,
} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CopyIcon from 'src/images/icons/copy.svg'
import DoneCircle from 'src/images/icons/done-circle.svg'
import { concatFirstAndLastNames } from 'src/utilities/functions'

const pageName = 'Share Resume Modal'

const BaseTextInput = styled(TextInput)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`
const LinkedInProfileURLInput = styled(BaseTextInput)`
`
const FirstNameInput = styled(BaseTextInput)`
`
const LastNameInput = styled(BaseTextInput)`
`
const CompanyNameInput = styled(BaseTextInput)`
`
const JobTitleInput = styled(BaseTextInput)`
`

const StyledLinkedInLogo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`

const ShareButtons = ({
  url,
  title,
  onAfterCopiedToClipboard,
}) => {

  const iconSize = 32
  const iconBorderRadius = 8
  const buttonClassName = 'btn btn-sm btn-outline-secondary'

  const trackShare = ({type, url, title}) => {
    trackEvent('resume_share_click', {
        title,
        type,
        url,
      })
  }
  return (
    <div
      style={ styles.shareButtons }
    >
      <CopyToClipboard
        text={url}
        onCopy={() => onAfterCopiedToClipboard()}
      >
        <button
          style={ styles.shareButton }
          className={buttonClassName}
        >
          <CopyIcon
            style={ {...styles.shareButtonIcon, ...styles.copyIcon} }
          />
          Copy Link
        </button>
      </CopyToClipboard>
      <FacebookShareButton
        url={url}
        quote={title}
        beforeOnClick={ () => trackShare({type: 'facebook', url, title}) }
        style={ styles.shareButton }
        resetButtonStyle={false}
        className={buttonClassName}
      >
        <RSFacebookIcon style={ styles.shareButtonIcon } size={ iconSize } borderRadius={ iconBorderRadius }/>
        Facebook
      </FacebookShareButton>
      <LinkedinShareButton
        url={url}
        title={title}
        summary=''
        beforeOnClick={ () => trackShare({type: 'linkedin', url, title}) }
        style={ styles.shareButton }
        resetButtonStyle={false}
        className={buttonClassName}
      >
        <RSLinkedInIcon style={ styles.shareButtonIcon } size={ iconSize } borderRadius={ iconBorderRadius }/>
        LinkedIn
      </LinkedinShareButton>
      <TwitterShareButton
        url={url}
        title={title}
        beforeOnClick={ () => trackShare({type: 'twitter', url, title}) }
        style={ styles.shareButton }
        resetButtonStyle={false}
        className={buttonClassName}
      >
        <RSTwitterIcon style={ styles.shareButtonIcon } size={ iconSize } borderRadius={ iconBorderRadius }/>
        Twitter
      </TwitterShareButton>
      <RedditShareButton
        url={url}
        title={title}
        beforeOnClick={ () => trackShare({type: 'reddit', url, title}) }
        style={ styles.shareButton }
        resetButtonStyle={false}
        className={buttonClassName}
      >
        <RedditIcon style={ styles.shareButtonIcon } size={ iconSize } borderRadius={ iconBorderRadius }/>
        Reddit
      </RedditShareButton>
      <EmailShareButton
        url={url}
        subject={title}
        beforeOnClick={ () => trackShare({type: 'email', url, title}) }
        style={ styles.shareButton }
        resetButtonStyle={false}
        className={buttonClassName}
      >
        <EmailIcon style={ styles.shareButtonIcon } size={ iconSize } borderRadius={ iconBorderRadius }/>
        Email
      </EmailShareButton>
    </div>
    )
}

const ShareModal = ({
  isOpen,
  onRequestClose,
  profile,
}) => {

  const {
    id: resumeId,
    personalDetails: {
      firstName,
      lastName,
    }
  } = profile

  const url = `${process.env.BASE_URL}/shared/${resumeId}`
  const fullName = concatFirstAndLastNames({
    firstName,
    lastName,
  })
  const title = `${fullName}'s Resume`

  const onAfterOpen = () => {
    trackPage(pageName)
  }
  const onAfterClose = () => {
    trackEvent('share_resume_modal_closed', {
      page: pageName,
    })
  }

  const [showCopiedToClipboardMessage, setShowCopiedToClipboardMessage] = useState(false)
  const onAfterCopiedToClipboard = () => {
    setShowCopiedToClipboardMessage(true)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Share Modal'
      {...{onAfterOpen, onAfterClose}}
    >
      <div
        className='text-center form-group'
        style={ styles.modalBody }
      >
        <h2 className='font-weight-bold text-center mb-3 d-flex align-items-center justify-content-center'>
          Share Your Resume
        </h2>
        <input
          readonly
          className='form-control'
          value={url}
          style={styles.urlText}
          onClick={(e) => e.target.select()}
        />

        <ShareButtons
          {...{
            url,
            title,
            onAfterCopiedToClipboard,
          }}
        />
        <Toast
          onClose={() => setShowCopiedToClipboardMessage(false)}
          show={showCopiedToClipboardMessage}
          delay={3000}
          autohide
          style={ styles.toast }
        >
          <Toast.Header>
            <DoneCircle
              width={20}
              height={20}
              style={styles.checkmark}
              color='green'
            />
            <strong className="me-auto">Link Copied</strong>
          </Toast.Header>
        </Toast>
      </div>
    </Modal>
    )
}

const styles = {
  modalBody: {
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  shareButtons: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  shareButton: {
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '49%',
    alignItems: 'center',
    paddingLeft: 20,
    marginBottom: 10,
  },
  shareButtonIcon: {
    marginRight: 10,
  },
  copyIcon: {
    width: 32,
    height: 32,
  },
  urlText: {
    width: '100%',
    marginBottom: 10,
    paddingLeft: 20,
  },
  toast: {
    position: 'absolute',
    bottom: 20,
  },
  checkmark: {
    marginRight: 10,
  },
}

export default ShareModal
