import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import CloudFinish from 'src/images/icons/cloud-finish.svg'

const PreviewerSpinner = ({isLoading, width}) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    width: width,
    alignItems: 'center',
    marginBottom: 10,
  }
  const spinnerContainerStyle = {
    marginRight: 10,
  }
  const spinnerStyle = {
    visibility: isLoading ? 'initial' : 'hidden',
  }
  const textStyle = {
    color: 'white',
    fontSize: '.8rem',
    userSelect: 'none',
  }
  return (
    <div style={containerStyle}>
      <div style={spinnerContainerStyle}>
        {
          isLoading ?
          (
            <Spinner
              animation="border"
              variant='light'
              role="status"
              size='sm'
              style={spinnerStyle}
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <CloudFinish/>
          )
        }
      </div>
      <div style={textStyle}>
        {
          isLoading ? 'Updating...' : 'Saved'
        }
      </div>
    </div>
    )
}

export default PreviewerSpinner
