import React, { useState, useEffect, } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce'
import {
  RecommendedContentBox,
  ResultContainerHeader,
  ResultItemContainer,
  LeftArrow,
  TextContainer,
  SubphraseItem,
} from 'src/components/recommended-content/RecommendedContentBox'
import { profilePhraseIndex } from 'src/utilities/algolia'
import {popularProfilePhrases as popularPhrases} from 'src/constants/popular_profile_phrases'

const RecommendedProfilePhrasesContainer = styled.div`
`

const SectionContainer = styled.div`
`

const PhraseSection = ({
  title,
  phrases,
  onClickPhrase,
  professionalSummary,
}) => {
  return (
    <SectionContainer>
      <ResultContainerHeader>
        {title}
      </ResultContainerHeader>
      {
        phrases.map(phrase => (
          <ProfilePhraseItem
            key={phrase.text}
            {...{
              phrase,
              onClickPhrase,
              professionalSummary,
            }}
          />
        ))
      }
    </SectionContainer>
  )
}

const ProfilePhraseItem = ({
  phrase,
  onClickPhrase,
  professionalSummary,
}) => {

  // want to highlight children subphrases if hovered, and parent container
  // if hovering inside but not over a child
  // hovered index refers to the index of the hovered subphrase
  // and -1 if it is the parent container
  // and null if no hover
  const [hoveredIndex, setHoveredIndex] = useState(null)

  const handleOnMouseEnter = (index) => {
    setHoveredIndex(index)
  }

  const handleOnMouseLeave = (index) => {
    // if leaving child, hover parent
    if (index > -1) {
      setHoveredIndex(-1)
    } else {
      // if leaving parent, remove hover
      setHoveredIndex(null)
    }
  }

  return (
    <ResultItemContainer
      onClick={() => onClickPhrase(phrase)}
      onMouseEnter={() => handleOnMouseEnter(-1)}
      onMouseLeave={() => handleOnMouseLeave(-1)}
    >
      <LeftArrow
        shouldHighlight={ hoveredIndex !== null }
      />
      <TextContainer>
        {
          phrase.subphrases.map((subphrase, index) => {

            const isGrayedOut = professionalSummary.includes(subphrase.text)
            // pass hover on parent down to highlight all subphrases
            const shouldHighlight = hoveredIndex == index || hoveredIndex == -1

            const onClick = (e) => {
              e.stopPropagation()
              onClickPhrase(subphrase)
            }

            return (
              <SubphraseItem
                {...{
                  isGrayedOut,
                  onClick,
                  shouldHighlight,
                }}
                key={subphrase.uuid}
                onMouseEnter={() => handleOnMouseEnter(index)}
                onMouseLeave={() => handleOnMouseLeave(index)}
              >
                {subphrase.text}
              </SubphraseItem>
              )
          })
        }
      </TextContainer>
    </ResultItemContainer>
    )
}

const popularSection = {
  title: 'Popular',
  phrases: popularPhrases,
}

// debounce to throttle calls to search index while typing
// looks a little different here since debounce doesn't really expect promises
// https://github.com/lodash/lodash/issues/4400#issuecomment-834800398
const debounced = debounce((callback) => {
    return callback();
}, 500);

const RecommendedProfilePhrasesBox = ({
  onClickPhrase,
  jobTitle,
  professionalSummary,
}) => {

  // there is always a popular section of general popular phrases
  // above that, if there is a job title for either the employment history item, or the resume
  // that will produce a section of results for that job title query
  // and above that, if there is a query string, there will be a section for those results

  const [queryString, setQueryString] = useState('')
  const [queryResult, setQueryResult] = useState(null)
  const [jobTitleQueryResult, setJobTitleQueryResult] = useState(null)
  const [sections, setSections] = useState([popularSection])

  // query result generates on query string change
  useEffect(() => {
    if (!queryString.trim()) {
      return
    }
    // debounce to throttle calls to search index while typing
    debounced(() => {
      profilePhraseIndex.search(queryString)
      .then(result => {
        setQueryResult(result)
      })
    })

  }, [queryString])

  // job title query generates on job title change
  useEffect(() => {
    if (!jobTitle.trim()) {
      return
    }
    // debounce to throttle calls to search index while typing
    debounced(() => {
      profilePhraseIndex.search(jobTitle, {
        restrictSearchableAttributes: [
          'job_title_name',
        ],
      })
      .then(result => {
        setJobTitleQueryResult(result) // query result has hits which is phrase objects
      })
    })

  }, [jobTitle])

  // update sections when
  // query result or job titles change
  useEffect(() => {

    let queryResultSection = null
    if (queryString.trim() && queryResult?.hits && queryResult.hits.length > 0) {
      queryResultSection = {
        title: queryString,
        phrases: queryResult.hits,
      }
    }

    let jobTitleResultSection = null
    if (jobTitleQueryResult?.hits && jobTitleQueryResult.hits.length > 0) {
      jobTitleResultSection = {
        title: jobTitle,
        phrases: jobTitleQueryResult.hits,
      }
    }

    const sections = [queryResultSection, jobTitleResultSection, popularSection].filter(section => section)
    setSections(sections)

  }, [queryString, queryResult, jobTitleQueryResult])

  const onChangeQueryString = (text) => {
    setQueryString(text)
  }

  return (
    <RecommendedContentBox
      placeholder='Search for phrases to add to your resume'
      {...{onChangeQueryString}}
    >
      <RecommendedProfilePhrasesContainer>
        {
          sections.map((section, index) => {
            return (
              <PhraseSection
                title={section.title}
                phrases={section.phrases}
                key={`${section.title}-${index}`}
                onClickPhrase={onClickPhrase}
                professionalSummary={professionalSummary}
              />
            )
          })
        }
      </RecommendedProfilePhrasesContainer>
    </RecommendedContentBox>
    )
}

export default RecommendedProfilePhrasesBox
