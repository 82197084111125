import React from "react"
import { Router } from "@reach/router"
import ResumesIndex from 'src/components/resumes/index'
import ResumesShow from 'src/components/resumes/show'

const ResumesRouter = () => {
  return (
    <Router basepath='/resumes'>
      <ResumesShow path='/:resume_id'/>
      <ResumesIndex path='/'/>
    </Router>
    )
}

export default ResumesRouter
