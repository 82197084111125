import React, {useState, useEffect} from 'react';
import {
  graphql,
  useStaticQuery,
} from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive'
import Spinner from 'react-bootstrap/spinner'
import Picker from 'rmc-picker'
import Popup from 'rmc-picker/lib/Popup'
import 'rmc-picker/assets/index.css';
import 'rmc-picker/assets/popup.css';
import { trackPage, trackEvent} from 'src/utilities/analytics'
import PreviewerSpinner from 'src/components/resumes/PreviewerSpinner'
import withSize from 'src/utilities/withSize'
import Previewer from 'src/components/resumes/previewer'
import NavigationAngleLeft from 'src/images/icons/navigation-angle-left.svg'
import NavigationCheck from 'src/images/icons/navigation-check.svg'
import previewThemes from 'src/themes/previewThemes'
import Paginator from 'src/components/shared/Paginator'
import 'src/styles/theme-previewer.css'

const pageName = 'Theme Previewer'

// responsive image queries for theme template images
export const imageQuery = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "templates" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxWidth: 250, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const Container = ({children}) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    // this may help with weird scrolling issues on mobile
    position: 'fixed',
    inset: 0, // apparently this isn't supported on iOS, so...:
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  }
  return (
    <div style={containerStyle}>
      {children}
    </div>
    )
}

const BackButton = ({onClick}) => {
  // make back button more compact and farther left on phones
  const isPhone = useMediaQuery({ query: '(max-width: 576px)' })
  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: isPhone ? 0 : 10,
    borderRadius: 10,
  }
  const textStyle = {
    marginLeft: 5,
    color: 'white',
    marginRight: 5,
  }
  // shift icon left to line up against button edge
  const iconStyle = {
    marginLeft: -5,
  }
  return (
    <div style={containerStyle} onClick={onClick} className="cursor-pointer gray-800-50-on-hover">
      <NavigationAngleLeft fill='white' style={iconStyle}/>
      <span style={textStyle}>Back to Editor</span>
    </div>
    )
}

const navBarHeight = 64

const NavBar = ({toggleView, onClickDownloadPDF, isDownloadButtonLoading,}) => {
  const isPhone = useMediaQuery({ query: '(max-width: 576px)' })
  const containerStyle = {
    width: '100%',
    height: navBarHeight,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
  const leftOrRightButtonContainerStyle = {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
  const horizontalEdgeMargin = isPhone ? 10 : 20
  const backButtonContainerStyle = {
    ...leftOrRightButtonContainerStyle,
    marginLeft: horizontalEdgeMargin,
  }
  const rightContainerStyle = {
    ...leftOrRightButtonContainerStyle,
    marginRight: horizontalEdgeMargin,
  }
  const downloadButtonClassNameSize = isPhone ? 'xs' : 'sm'
  const downloadButtonContent = isDownloadButtonLoading ? <Spinner animation="border" variant='light'/> : 'Download PDF'
  return (
    <div style={containerStyle} className="bg-gray-900">
      <div style={backButtonContainerStyle}>
        <BackButton onClick={toggleView}/>
      </div>
      <div style={rightContainerStyle}>
        <button
          className={`btn btn-primary btn-${downloadButtonClassNameSize}`}
          onClick={ () => {
            onClickDownloadPDF({pageName})
          }}
          disabled={isDownloadButtonLoading}
        >
          {downloadButtonContent}
        </button>
      </div>
    </div>
    )
}

const ContentContainer = ({children}) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    height: `calc(100vh - ${navBarHeight}px`,
  }
  return (
    <div style={containerStyle} className="bg-gray-800 always-show-scrollbar">
      {children}
    </div>
    )
}

const CellImage = styled(Img)`
  width: 100%;
  border-radius: 3px;
`

const ThemeSelectionCell = ({imageData, title, themeKey, selectedThemeKey, setSelectedThemeKey, cellColumnsPerRow,}) => {
  const onClick = () => {
    setSelectedThemeKey(themeKey)
  }
  const isSelected = selectedThemeKey === themeKey
  const outlineClass = isSelected ? 'outline-primary' : ''
  const combinedOutlineClass = `outline-primary-on-hover ${outlineClass}`

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,
    width: 'calc(100% - 12px)',
    cursor: 'pointer',
  }
  if (cellColumnsPerRow === 2) {
    containerStyle.width = 'calc(50% - 12px)' // 2 per row
  }
  const titleStyle = {
    color: 'white',
    marginBottom: 2,
  }
  // have this image border container that holds
  // image and border
  // so that border goes completely outside of image instead of covering image
  const imageBorderContainerStyle = {
    width: '100%',
    borderRadius: 3,
    position: 'relative',
  }
  const imageBorderStyle = {
    position: 'absolute',
    inset: -5, // corresponds to border width
    borderRadius: 7,
  }

  const checkCircleStyle = {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
    backgroundColor: '#335EEA',
    borderRadius: '50%',
    width: 40,
    height: 40,
    visibility: isSelected ? 'visible' : 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  }

  return (
    <div style={containerStyle} onClick={onClick}>
      <div style={titleStyle}>{title}</div>
      <div style={imageBorderContainerStyle}>
        <CellImage
          fluid={imageData}
        />
        <div style={imageBorderStyle} className={combinedOutlineClass}/>
        <div style={checkCircleStyle}>
          <NavigationCheck fill='white'/>
        </div>
      </div>
    </div>
    )
}

const ThemeSelectionContainer = ({profile, selectedThemeKey, setSelectedThemeKey, themeSelectionContainerWidth, cellColumnsPerRow, previewThemes}) => {
  if (themeSelectionContainerWidth === 0) {
    return null
  }
  const containerStyle = {
    overflowY: 'scroll',
    borderRight: '1px solid #385b74',
    width: themeSelectionContainerWidth,
  }

  const columnStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 5,
    justifyContent: 'space-between',
    padding: 20,
  }
  const cells = previewThemes.map((theme) => (
      <ThemeSelectionCell
        imageData={theme.imageData}
        title={theme.name}
        key={theme.key}
        themeKey={theme.key}
        {...{selectedThemeKey, setSelectedThemeKey, cellColumnsPerRow}}
      />
    ))

  return (
    <div style={containerStyle}>
      <div style={columnStyle}>
        {cells}
      </div>
    </div>
    )
}

const PreviewerContainer = ({profile, themeSelectionContainerWidth, size, isMobile, selectedThemeKey, setSelectedThemeKey, setCurrentBlobURL, updatePreviewURL,}) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [numPages, setNumPages] = useState(1) // numPages gets set in pdf viewer

  // loading state and callback used to tie together
  // state of pdf rendering in previewer and the spinner state we show here
  const [isLoading, setIsLoading] = useState(true)
  const loadingCallback = (isLoading) => {
    setIsLoading(isLoading)
  }

  const {width, height} = size // this container's size via sizeme package

  const containerStyle = {
    padding: '30px 0px',
    width: `calc(100% - ${themeSelectionContainerWidth}px)`, // need responsive breakpoints to match widths of ThemeSelectionContainer
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'scroll',
  }
  const innerContainerStyle = {
    margin: '40px 0px',
  }

  // 925 is the big screen maximum and preferred page width
  // .9 of the width of this container is the biggest it should get
  let pageWidth = 925
  const proportionalWidth = width * .9
  pageWidth = Math.min(pageWidth, proportionalWidth)

  const previousButtonEnabled = pageNumber > 1
  const nextButtonEnabled = pageNumber < numPages

  const onClickPreviousPage = () => {
    setPageNumber(pageNumber - 1)
  }
  const onClickNextPage = () => {
    setPageNumber(pageNumber + 1)
  }
  const buttonContainerStyle = {
    position: 'fixed',
    bottom: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    zIndex: 5,
  }

  return (
    <div style={containerStyle}>
      <div style={innerContainerStyle}>
        <PreviewerSpinner width={pageWidth} {...{isLoading}}/>
        <Previewer {...{profile, pageWidth, pageNumber, setNumPages, loadingCallback, setCurrentBlobURL, updatePreviewURL,}}/>
      </div>
      <div style={buttonContainerStyle}>
        <Paginator {...{pageNumber, numPages, onClickPreviousPage, onClickNextPage, previousButtonEnabled, nextButtonEnabled}}/>
        {
          isMobile &&
          <MobileThemePicker {...{selectedThemeKey, setSelectedThemeKey,}}/>
        }
      </div>
    </div>
    )
}

const PreviewerContainerWithSize = withSize(PreviewerContainer)

const MobileThemePicker = ({selectedThemeKey, setSelectedThemeKey,}) => {
  // currentValue reflects the picker's selected state
  // vs selectedThemeKey which reflects the selected theme which will be used to render preview
  // when picker pops up, currentValue should reflect selectedThemeKey
  // when user taps 'ok', the change is committed by updating selectedThemeKey to match currentValue
  const [currentValue, setCurrentValue] = useState(selectedThemeKey)
  const items = previewThemes.map((theme) => (
                                        <Picker.Item
                                          value={theme.key}
                                          key={theme.key}
                                        >
                                          {theme.name}
                                        </Picker.Item>)
                                      )
  const onValueChange = (value) => {
    setCurrentValue(value)
  }
  const onScrollChange = (value) => {
    // noop
  }
  // pop up
  const popupContent = (
      <Picker
        selectedValue={currentValue}
        disabled={false}
        onValueChange={onValueChange}
        onScrollChange={onScrollChange}
      >
        {items}
      </Picker>
    )
  const onDismiss = () => {
    setCurrentValue(selectedThemeKey)
  }
  const onOk = (newValue) => {
    // commit theme when user chooses ok
    setSelectedThemeKey(currentValue)
  }

  return (
    <Popup
      className="fortest"
      transitionName="rmc-picker-popup-slide-fade"
      maskTransitionName="rmc-picker-popup-fade"
      content={popupContent}
      title="Pick a Theme"
      disabled={false}
      onDismiss={onDismiss}
      onOk={onOk}
      value={null}
    >
      <MobileThemePickerToggleButton/>
    </Popup>
    )
}

const MobileThemePickerToggleButton = ({onClick}) => {
  const buttonStyle = {
    marginLeft: 20,
  }
  return (
    <button
      style={buttonStyle}
      className='btn btn-dark btn-sm'
      {...{onClick}}
    >
      Pick Theme
    </button>
    )
}

const ThemePreviewer = ({ profile, setSelectedTheme, toggleView, onClickDownloadPDF, setCurrentBlobURL, isDownloadButtonLoading, updatePreviewURL,}) => {

  // responsive images:
  const imageData = useStaticQuery(imageQuery)

  // create map
  // image filename: image data
  // to pull into ordered preview themes
  const imageFilenameToImageDataMap = {}
  imageData.allFile.edges.forEach(image => {
    const node = image.node
    imageFilenameToImageDataMap[node.base] = node.childImageSharp.fluid
  })

  const previewThemesWithImageData = previewThemes.map(theme => {
    return {
      ...theme,
      imageData: imageFilenameToImageDataMap[theme.imageFilename]
    }
  })

  // Assume Tablet or Mobile width window, so theme selection container toggles into view
  const isXLWidth = useMediaQuery({ query: '(min-width: 1280px)' })
  const isDesktopWidth = useMediaQuery({ query: '(min-width: 768px)' })
  const isMobile = !isXLWidth && !isDesktopWidth
  let themeSelectionContainerWidth = 0
  let cellColumnsPerRow = 0
  if (isXLWidth) {
    themeSelectionContainerWidth = 409
    cellColumnsPerRow = 2
  } else if (isDesktopWidth) {
    themeSelectionContainerWidth = 265
    cellColumnsPerRow = 1
  }

  // theme key
  const selectedThemeKey = profile.metadata.theme
  const setSelectedThemeKey = (themeKey) => {
    setSelectedTheme(themeKey)
  }

  useEffect(() => {
    trackPage(pageName)
  }, [])

  return (
    <Container>
      <NavBar {...{toggleView, onClickDownloadPDF, isDownloadButtonLoading,}}/>
      <ContentContainer>
        <ThemeSelectionContainer
          {...{profile, selectedThemeKey, setSelectedThemeKey, themeSelectionContainerWidth, cellColumnsPerRow}}
          previewThemes={previewThemesWithImageData}
        />
        <PreviewerContainerWithSize
          {...{profile, themeSelectionContainerWidth, isMobile, selectedThemeKey, setSelectedThemeKey, setCurrentBlobURL, updatePreviewURL, }}
        />
      </ContentContainer>
    </Container>
    )
};

export default ThemePreviewer;
