import React, {useEffect, useState, useRef} from "react"
import styled from 'styled-components';
import Modal from 'src/components/Modal'
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import { trackPage, trackEvent} from 'src/utilities/analytics'
import { useMediaQuery } from 'react-responsive'
import Spinner from 'react-bootstrap/spinner'
import TextInput from 'src/components/shared/TextInput'
import Button from 'src/components/shared/Button'
import XCircle from 'src/images/icons/x-circle.svg'
import {makeSafeProfile} from 'src/utilities/safe-profile'
import { getLinkedInProfile, resolveAndGetLinkedInProfile, } from 'src/utilities/http-requests'
import { transformProfileFromProxycurl } from 'src/utilities/proxycurl'
import LinkedInLogo from 'src/images/linked-in-logo-150.png'

const pageName = 'LinkedIn Import Modal'

const BaseTextInput = styled(TextInput)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`
const LinkedInProfileURLInput = styled(BaseTextInput)`
`
const FirstNameInput = styled(BaseTextInput)`
`
const LastNameInput = styled(BaseTextInput)`
`
const CompanyNameInput = styled(BaseTextInput)`
`
const JobTitleInput = styled(BaseTextInput)`
`

const StyledLinkedInLogo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`

const ImportProfileView = ({
  onClickImportProfile,
  onClickToggleView,
  onRequestClose,
  linkedInProfileURL,
  setLinkedInProfileURL,
  isLoading,
}) => {
  return (
    <div>
      <h2 className='font-weight-bold text-center mb-3 d-flex align-items-center justify-content-center'>
        <StyledLinkedInLogo src={LinkedInLogo}/>
        LinkedIn Profile Import
      </h2>
      <h4>
        We can import your LinkedIn profile to create your resume for you. Put in your LinkedIn profile URL below:
      </h4>
      <LinkedInProfileURLInput
        label="LinkedIn Profile URL"
        value={linkedInProfileURL}
        type="url"
        onChange={event => setLinkedInProfileURL(event.target.value)}
        placeholder='https://linkedin.com/in/johnsmith516'
      />
      <Button
        className={`btn-block mt-5`}
        onClick={onClickImportProfile}
        isLoading={isLoading}
      >
        Import Profile
      </Button>
      <Button
        className={`btn-block mt-5`}
        variant='secondary'
        onClick={onClickToggleView}
        isLoading={isLoading}
      >
        I Don't Know My LinkedIn Profile URL
      </Button>
      <Button
        className={`btn-block mt-5`}
        variant='outline-secondary'
        onClick={onRequestClose}
        isLoading={isLoading}
        spinnerVariant='primary'
      >
        I Don't Have LinkedIn / No Thanks
      </Button>
    </div>
    )
}

const ResolveProfileView = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  companyName,
  setCompanyName,
  jobTitle,
  setJobTitle,
  onRequestClose,
  isLoading,
  onClickFindProfile,
  onClickToggleView,
}) => {
  return (
    <div>
      <h2 className='font-weight-bold text-center mb-3'>
        LinkedIn Profile Search
      </h2>
      <h4>
        Enter your details below to find and import your LinkedIn profile:
      </h4>
      <FirstNameInput
        label="First Name"
        value={firstName}
        onChange={event => setFirstName(event.target.value)}
      />
      <LastNameInput
        label="Last Name"
        value={lastName}
        onChange={event => setLastName(event.target.value)}
      />
      <CompanyNameInput
        label="Company Name"
        value={companyName}
        onChange={event => setCompanyName(event.target.value)}
      />
      <JobTitleInput
        label="Job Title"
        value={jobTitle}
        onChange={event => setJobTitle(event.target.value)}
      />
      <Button
        className={`btn-block mt-5`}
        onClick={onClickFindProfile}
        isLoading={isLoading}
      >
        Find & Import Profile
      </Button>
      <Button
        className={`btn-block mt-5`}
        onClick={onClickToggleView}
        isLoading={isLoading}
        variant='secondary'
      >
        I Already Know My LinkedIn Profile URL
      </Button>
      <Button
        className={`btn-block mt-5`}
        onClick={onRequestClose}
        isLoading={isLoading}
        variant='outline-secondary'
        spinnerVariant='primary'
      >
        I Don't Have LinkedIn / No Thanks
      </Button>
    </div>
    )
}

const LabelledTextDisplay = ({title, content}) => {
  if (!content) {
    return null
  }
  const maxLength = 100
  let _content = content && content.length > maxLength ? `${content.substring(0, maxLength)}...` : content

  return (
    <div>
      <h6>
        {title}
      </h6>
      <p>
        {_content}
      </p>
    </div>
    )
}

const AvatarImage = ({url}) => {
  return (
    <div className='avatar avatar-xl mt-4 mb-4'>
      <img className='avatar-img rounded-circle'
        src={url}
      />
    </div>
    )
}

const ShowResolvedProfileView = ({
  proxycurlProfile,
  isLoading,
  onClickConfirmProfile,
  onClickCancelResolvedProfile,
}) => {
  const {
    full_name,
    summary,
    headline,
    // occupation,
    city,
    state,
    profile_pic_url,
    // experiences,
    // education,
    // image_url, // which image?
  } = proxycurlProfile
  return (
    <div>
      <h2 className='font-weight-bold text-center mb-3'>
        LinkedIn Profile Found
      </h2>
      <h4>
        Is This Your Profile?
      </h4>
      <AvatarImage
        url={profile_pic_url}
      />
      <LabelledTextDisplay
        title='Name'
        content={full_name}
      />
      <LabelledTextDisplay
        title='Headline'
        content={headline}
      />
      <LabelledTextDisplay
        title='Summary'
        content={summary}
      />
      <LabelledTextDisplay
        title='City'
        content={city}
      />
      <Button
        className={`btn-block mt-5`}
        onClick={() => onClickConfirmProfile(proxycurlProfile)}
        isLoading={isLoading}
      >
        Import Profile
      </Button>
      <Button
        className={`btn-block mt-5`}
        variant='outline-secondary'
        onClick={onClickCancelResolvedProfile}
        isLoading={isLoading}
        spinnerVariant='primary'
      >
        Cancel
      </Button>

    </div>
    )
}

const AlertStyledDiv = styled.div`
  max-width: 500px;
`
const AlertMessage = Object.freeze({
  FailedRequest: 'Something went wrong. Make sure you filled in your information correctly and are connected to the internet.',
  NotLinkedInURL: 'This is not a LinkedIn profile URL. LinkedIn Profile URLs look like this: "https://linkedin.com/in/johnsmith516"',
  LinkedInImportWarning: 'Warning: Importing from LinkedIn will replace the information you have filled in.',
  FieldsNotFilled: 'Please fill in all your details.',
  None: 'None',
})

const Alert = ({message}) => {
  if (message == AlertMessage.None) {
    return null
  }
  return (
    <AlertStyledDiv className='alert alert-danger text-center'>
       {message}
    </AlertStyledDiv>)
}

const LinkedInImportModalScreen = Object.freeze({
  Import: 'Import',
  Resolve: 'Resolve',
  Show: 'Show',
})

const LinkedInImportModal = ({isOpen, onRequestClose, fetchedProfileHandler, isProfileEmpty}) => {
  // proxycurl input values for getting linkedin profile data
  const [linkedInProfileURL, setLinkedInProfileURL] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [jobTitle, setJobTitle] = useState(null)
  // proxycurl data
  const [proxycurlProfile, setProxycurlProfile] = useState({})
  // UI
  const [isLoading, setIsLoading] = useState(false)
  const [screen, setScreen] = useState(LinkedInImportModalScreen.Import)
  // alert
  const [alertMessage, setAlertMessage] = useState(AlertMessage.None)

  const modalBodyStyle = {
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }

  const onAfterOpen = () => {
    trackPage(pageName)
  }
  const onAfterClose = () => {
    trackEvent('linkedin_import_modal_closed', {
      page: pageName,
    })
  }

  useEffect(() => {
    if (!isProfileEmpty) {
      setAlertMessage(AlertMessage.LinkedInImportWarning)
    }
  }, [isProfileEmpty])

  const textAreaStyle = {
    width: '100%',
    margin: 20,
    padding: 20,
  }

  const proxycurlProfileHandler = (proxycurlProfile) => {
    fetchedProfileHandler(
      makeSafeProfile(
        transformProfileFromProxycurl(proxycurlProfile)
      )
    )
  }

  const onClickImportProfile = () => {
    let url = linkedInProfileURL
    if (!url || !url.includes('linkedin.com/in/')) {
      setAlertMessage(AlertMessage.NotLinkedInURL)
      trackEvent('linked_in_modal_clicked_import_profile', {
        page: pageName,
        url,
        earlyExit: true,
      })
      return
    }

    setAlertMessage(AlertMessage.None)
    setIsLoading(true)

    if (url.startsWith('linkedin.com/in')) {
      url = `https://www.${url}`
    } else if (url.startsWith('www.linkedin.com/in')) {
      url = `https://${url}`
    }

    getLinkedInProfile({url})
    .then(proxycurlProfile => {
      proxycurlProfileHandler(proxycurlProfile)
      setAlertMessage(AlertMessage.None)
      setIsLoading(false)
    })
    .catch(error => {
      console.error('Import profile error: ', error)
      setIsLoading(false)
      setAlertMessage(AlertMessage.FailedRequest)
      trackEvent('linked_in_import_error', {
        page: pageName,
        error: error.toString(),
        url,
        kind: 'import',
      })
    })
    trackEvent('linked_in_modal_clicked_import_profile', {
      page: pageName,
      url,
      earlyExit: false,
    })
  }

  const onClickToggleView = () => {
    // toggles back and forth between import and resolve
    const newScreen = screen == LinkedInImportModalScreen.Import ?
    LinkedInImportModalScreen.Resolve : LinkedInImportModalScreen.Import
    setScreen(newScreen)
    trackEvent('linked_in_modal_toggle_view', {
      page: pageName,
      newScreen,
    })
  }

  const onClickFindProfile = () => {
    if (!firstName || !lastName || !companyName || !jobTitle) {
      setAlertMessage(AlertMessage.FieldsNotFilled)
      trackEvent('linked_in_modal_clicked_find_profile', {
        page: pageName,
        earlyExit: true,
        firstName,
        lastName,
        companyName,
        jobTitle,
      })
      return
    }

    setAlertMessage(AlertMessage.None)
    setIsLoading(true)

    resolveAndGetLinkedInProfile({
      first_name: firstName,
      last_name: lastName,
      company_domain: companyName,
      title: jobTitle,
    })
    .then(proxycurlProfile => {
      setProxycurlProfile(proxycurlProfile)
      setScreen(LinkedInImportModalScreen.Show)
      setAlertMessage(AlertMessage.None)
      setIsLoading(false)
    })
    .catch(error => {
      console.error('Error finding profile: ', error)
      setIsLoading(false)
      setAlertMessage(AlertMessage.FailedRequest)
      trackEvent('linked_in_import_error', {
        page: pageName,
        error: error.toString(),
        firstName,
        lastName,
        companyName,
        jobTitle,
        kind: 'resolve_and_import',
      })
    })
    trackEvent('linked_in_modal_clicked_find_profile', {
      page: pageName,
      firstName,
      lastName,
      companyName,
      jobTitle,
    })
  }

  const onClickConfirmProfile = (profile) => {
    trackEvent('linked_in_modal_clicked_confirm_found_profile', {
      page: pageName,
      firstName,
      lastName,
      companyName,
      jobTitle,
      public_identifier: profile.public_identifier,
    })
    proxycurlProfileHandler(profile)
  }

  const onClickCancelResolvedProfile = () => {
    setScreen(LinkedInImportModalScreen.Resolve)
    onRequestClose()
  }

  let ScreenComponent
  switch(screen) {
    case LinkedInImportModalScreen.Import:
      ScreenComponent = ImportProfileView
      break
    case LinkedInImportModalScreen.Resolve:
      ScreenComponent = ResolveProfileView
      break
    case LinkedInImportModalScreen.Show:
      ScreenComponent = ShowResolvedProfileView
      break
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='LinkedIn Import Modal'
      {...{onAfterOpen, onAfterClose}}
    >
      <Alert
        message={alertMessage}
      />
      <div
        className='text-center'
        style={modalBodyStyle}
      >
        <ScreenComponent
          {...{
            // import
            onClickImportProfile,
            linkedInProfileURL,
            setLinkedInProfileURL,
            // resolve
            onClickFindProfile,
            firstName,
            setFirstName,
            lastName,
            setLastName,
            companyName,
            setCompanyName,
            jobTitle,
            setJobTitle,
            onClickCancelResolvedProfile,
            // show
            proxycurlProfile,
            onClickConfirmProfile,
            // shared
            onClickToggleView,
            onRequestClose,
            isLoading,

          }}
        />
      </div>
    </Modal>
    )
}

export default LinkedInImportModal
