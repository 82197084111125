import React, {useState, useEffect, useRef} from "react"
import PDFViewer from 'src/components/shared/PDFViewer'
import { renderResumeToPDF } from 'src/utilities/render-pdf'
import { uploadResumePreview } from 'src/utilities/storage'
import ReactDOM from 'react-dom'; // render in DOM

const LifeCycleEnum = Object.freeze({
  initialLoad: 'initial load',
  hidePrimary: 'hide primary',
  loadPrimary: 'load primary',
  showPrimary: 'show primary',
  loadAlt: 'load alt',
})

const Previewer = ({profile, pageWidth, style, setNumPages, pageNumber, loadingCallback, setCurrentBlobURL, updatePreviewURL,}) => {
  const currentBlobURL = useRef(null)
  const oldBlobURL = useRef(null)
  const primaryCanvasRef = useRef(null)
// life cycle on this component is pretty important
// the goal is to hide the flicker that happens when a pdf loads
// which happens everytime we update the profile / theme
// there's 2 pdf viewers. each lifecycle stage corresponds to an explicit render
// we want to trigger. we trigger the cycle when we want to update the pdf
// steps:
// 1. hide the primary - to hide the loading flicker. alt is still visible
// 2. load primary - once primary is hidden we feed it the new pdf to load
// 3. show primary - once primary finishes loading, we show it
// 4. load alt - alt is hidden since we are showing primary. now update that to current pdf
// for beginning of next cycle
  const [lifeCycleStage, setLifeCycleStage] = useState(LifeCycleEnum.initialLoad)
  // use effect with timeout and cleanup provides several great things
  // 1. rendering is not blocked on generating resume document, since it's an effect
  // 2. renders don't get queued up. on every change in profile, this component
  // re-renders and previous document render is cleaned up as part of useEffect cleanup
  // 3. timeout makes it so document re-render doesn't happen until user has
  // stopped typing for long enough to clear the docUpdateTimeout
  const docUpdateTimeout = 500 // doc updates throttled to once per this many milliseconds
  useEffect(() => {
    if (loadingCallback) {
      loadingCallback(true)
    }
    const timer = setTimeout(() => {
      // generate pdf blob then when it's finished and you have url, set to state to render
      // show second pdf viewer using old blob
      // trigger creating new pdf and update of primary pdf viewer
      // update second pdf viewer with new blob
      // hide second pdf viewer
      renderResumeToPDF({profile})
      .then(({blob, blobURL}) => {
        setLifeCycleStage(LifeCycleEnum.hidePrimary)
        currentBlobURL.current = blobURL
        setCurrentBlobURL(blobURL) // callback to pass value up to ancestors
        setLifeCycleStage(LifeCycleEnum.loadPrimary)
      })
    }, docUpdateTimeout);
    return () => clearTimeout(timer);
  }, [profile]);

  const saveCanvasAsImage = () => {
    if (!primaryCanvasRef.current) return

    primaryCanvasRef.current.toBlob(jpgBlob => {
      // upload to storage, then update preview url
      uploadResumePreview({
        resumeId: profile.id,
        jpgBlob
      })
      .then(snapshot => {
        return snapshot.ref.getDownloadURL()
      })
      .then(downloadURL => {
        updatePreviewURL({previewURL: downloadURL})
        // DO NOT LEAVE HERE in production:
        // console.log('downloadURL: ', downloadURL)
      })
      .catch(error => {
        console.error('error updating resume preview', error)
      })

    }, 'image/jpeg')
  }

  const containerStyle = {
    ...style,
  }

  // this container holds the two pdf viewers
  const innerContainerStyle = {
    position: 'relative',
    // use page width and width/height ratio to
    // size this container
    // helps with consistent placement when pdfs
    // are loading and the components don't have real dimensions yet
    // eliminates visual artifacts
    width: pageWidth,
    height: pageWidth / .707,
  }
  const primaryPDFViewerStyle = {
    position: 'relative',
    zIndex: 1,
  }
  const altPDFViewerStyle = {
    position: 'absolute',
    zIndex: 2,
    inset: 0, // not supported on iOS, lol
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  }
  if (lifeCycleStage == LifeCycleEnum.hidePrimary || lifeCycleStage == LifeCycleEnum.loadPrimary) {
    primaryPDFViewerStyle.opacity = 0
    altPDFViewerStyle.opacity = 1
  } else if (lifeCycleStage == LifeCycleEnum.initialLoad) {
    primaryPDFViewerStyle.opacity = 0
    altPDFViewerStyle.opacity = 0
  } else {
    primaryPDFViewerStyle.opacity = 1
    altPDFViewerStyle.opacity = 0
  }

  const onPrimaryRenderSuccess = (pageNumber) => {
    setLifeCycleStage(LifeCycleEnum.showPrimary)
    const blobURLToDelete = oldBlobURL.current // reference this for cleanup
    oldBlobURL.current = currentBlobURL.current
    setLifeCycleStage(LifeCycleEnum.loadAlt)
    if (loadingCallback) {
      loadingCallback(false)
    }
    // cleanup old blob url as recommended by mozilla web api docs:
    if (blobURLToDelete) {
      URL.revokeObjectURL(blobURLToDelete)
    }
    if (pageNumber == 1) {
      saveCanvasAsImage()
    }
  }

  return (
    <div style={containerStyle}>
      <div style={innerContainerStyle}>
        <PDFViewer
          file={currentBlobURL.current}
          style={primaryPDFViewerStyle}
          onRenderSuccess={onPrimaryRenderSuccess}
          canvasRef={primaryCanvasRef}
          {...{pageWidth, pageNumber, setNumPages}}
        />
        <PDFViewer
           file={oldBlobURL.current}
           style={altPDFViewerStyle}
           {...{pageWidth, pageNumber, setNumPages}}
         />
      </div>
    </div>
  )
}

// Getting this error. See if it shows up when not using garamond
// TypeError: Cannot read property 'hasGlyphForCodePoint' of null
// Goes away when I remove pdf download link
// Fix or workaround seems to be here
// https://github.com/diegomura/react-pdf/issues/777
// <PDFDownloadLink document={doc} fileName="balls.pdf">
//   {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
// </PDFDownloadLink>
//?
//ReactDOM.render(<App />, document.getElementById('root'));

// save in file
// ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`);

export default Previewer
export {LifeCycleEnum}
