
// assumes profile has been passed through makeSafeProfile
const isProfileEmpty = (profile) => {
  const {
    personalDetails: {
      firstName,
      lastName,
      jobTitle,
      phone,
      email,
    },
    professionalSummary,
    websitesAndSocial,
    employmentHistory,
    education,
    skills,
  } = profile
  return !firstName &&
    !lastName &&
    !jobTitle &&
    !phone &&
    !email &&
    !professionalSummary &&
    websitesAndSocial.length == 0 &&
    employmentHistory.length == 0 &&
    education.length == 0 &&
    skills.length == 0
}

export {
  isProfileEmpty
}
