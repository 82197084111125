import React from 'react';
import AutosizeInput from 'react-input-autosize';
import 'src/styles/editor-header.css'

const Header = ({name, updateName}) => {
  const containerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 20px',
  }
  const autosizeInputStyle = {
    maxWidth: '100%',
  }
  return (
    <div style={containerStyle}>
      <AutosizeInput
        name="form-field-name"
        value={name}
        onChange={(event) => updateName(event.target.value)}
        placeholder='Untitled'
        inputClassName="h1 autosize-input"
        style={autosizeInputStyle}
      />
    </div>
    )
}

export default Header
