import React, { memo } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ColumnWrappedTextInput from 'src/components/shared/ColumnWrappedTextInput'
import SectionHeader from 'src/components/editor/SectionHeader'
import 'src/styles/editor-shared.css'

const PersonalDetails = ({ personalDetails, updatePersonalDetails }) => {
  return (
    <section className="w-auto editor-section">
      <Container fluid>
        <SectionHeader title='Personal Details'/>
        <Row>
          <ColumnWrappedTextInput
            label="Wanted Job Title"
            xs={12}
            md={6}
            value={personalDetails.jobTitle}
            onChange={ (event) => {updatePersonalDetails('jobTitle', event.target.value)}}
          />
          <ColumnWrappedTextInput
            label="First Name"
            xs={12}
            md={6}
            value={personalDetails.firstName}
            onChange={ (event) => {updatePersonalDetails('firstName', event.target.value)}}
          />
        </Row>
        <Row>
          <ColumnWrappedTextInput
            label="Last Name"
            xs={12}
            md={6}
            value={personalDetails.lastName}
            onChange={ (event) => {updatePersonalDetails('lastName', event.target.value)}}
          />
          <ColumnWrappedTextInput
            label="Email"
            xs={12}
            md={6}
            value={personalDetails.email}
            onChange={ (event) => {updatePersonalDetails('email', event.target.value)}}
            type='email'
          />
        </Row>
        <Row>
          <ColumnWrappedTextInput
            label="Phone"
            xs={12}
            md={6}
            value={personalDetails.phone}
            onChange={ (event) => {updatePersonalDetails('phone', event.target.value)}}
            type='tel'
          />
        </Row>
      </Container>
    </section>
  );
};

export default memo(PersonalDetails);
