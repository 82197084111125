import {pseudoUuid} from 'src/utilities/functions'

const cleanString = (s) => {
  return s.trim()
}

const transformDate = (proxycurlDate) => {
  // transforms date object from proxycurl / linkedin profile
  // into javascript date object
  if (!proxycurlDate) {
    return null
  }
  const {
    year,
    month,
    day,
  } = proxycurlDate

  return new Date(`${month || 1}/${day || 1}/${year || 1969}`)
}

const transformWorkExperience = (proxycurlExperience) => {
  // transforms 'experience' field of proxycurl / linkedin profile
  // into object compatible with native profile's employmentHistory object
  return {
    uuid: pseudoUuid(),
    jobTitle: cleanString(proxycurlExperience.title || ''),
    employer: cleanString(proxycurlExperience.company || ''),
    startDate: transformDate(proxycurlExperience.starts_at),
    endDate: transformDate(proxycurlExperience.ends_at),
    city: cleanString(proxycurlExperience.location || ''),
    description: `<p>${cleanString(proxycurlExperience.description || '')}</p>`,
    isCurrent: !proxycurlExperience.ends_at
  }
}

const transformEducation = (proxycurlEducation) => {
  // transforms 'education' field of proxycurl / linkedin profile
  // into object compatible with native profile's education object

  let degree

  const {
    field_of_study,
    degree_name,
  } = proxycurlEducation

  if (field_of_study && degree_name) {
    degree = `${degree_name}, ${field_of_study}`
  } else {
    degree = field_of_study || degree_name  || ''
  }
  // does not have city
  return {
    uuid: pseudoUuid(),
    school: cleanString(proxycurlEducation.school  || ''),
    degree: cleanString(degree || ''),
    city: null,
    startDate: transformDate(proxycurlEducation.starts_at),
    endDate: transformDate(proxycurlEducation.ends_at),
    description: `<p>${cleanString(proxycurlEducation.description || '')}</p>`,
    isCurrent: !proxycurlEducation.ends_at
  }
}
// transform proxycurl / linkedin profile into native profile format
const transformProfileFromProxycurl = (proxycurlProfile) => {
  const profile = {
    personalDetails: {
      firstName: cleanString(proxycurlProfile.first_name || ''),
      lastName: cleanString(proxycurlProfile.last_name || ''),
      jobTitle: cleanString(proxycurlProfile.headline || ''),
      email: cleanString(proxycurlProfile.email || ''),
      phone: cleanString(proxycurlProfile.phone || ''),
    },
    websitesAndSocial: [
      {
        uuid: pseudoUuid(),
        label: 'LinkedIn',
        link: `https://linkedin.com/in/${proxycurlProfile.public_identifier}`
      },
    ],
    professionalSummary: `<p>${cleanString(proxycurlProfile.summary || '')}</p>`,
    employmentHistory: proxycurlProfile.experiences.map(transformWorkExperience),
    education: proxycurlProfile.education.map(transformEducation),
    skills: []
  }
  return profile
}

export { transformProfileFromProxycurl }
