import React from 'react';
import styled from 'styled-components';
import Button from 'src/components/shared/Button'
import LinkedInLogo from 'src/images/linked-in-logo-150.png'

const StyledLinkedInLogo = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const LinkedInImport = ({onClick,}) => {
  const containerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 20px',
  }

  return (
    <div style={containerStyle}>
      <Button onClick={onClick}>
        <ButtonContent>
          <StyledLinkedInLogo src={LinkedInLogo}/>
          Import LinkedIn Profile
        </ButtonContent>
      </Button>
    </div>
    )
}

export default LinkedInImport
