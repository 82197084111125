// output from taxonomies app
// python manage.py print_data --type=default_profile_phrases
// most popular profile phrases without job title entities associated, although these do seem
// to be mostly obscure jobs so I manually deleted those with jobs in them.
// ranked by usage_count descending (most popular)
// 10/23/21
export const popularProfilePhrases = [
  {'objectID': 'cdb2c086-0af8-4150-923b-0fbd62d2f2e9', 'subphrases': [{'text': 'Self-motivated and enthusiastic worker with vast experience in many different fields.', 'usage_count': 257, 'uuid': 'f2ca2779-ffbc-4ecc-af99-d68e182e6ec6'}, {'text': 'Bringing forth a proven track record of achievement and a reputation for positive collaboration.', 'usage_count': 73, 'uuid': '6008b01d-188d-4b50-95df-3251a556afdf'}, {'text': 'Confident, hard working, and committed to seeing results in whatever position I am in.', 'usage_count': 344, 'uuid': 'b38e1462-dd09-4a75-af73-2cd2be420e9b'}, {'text': 'Reliable and dedicated to finding a place that will benefit from my superior skill set and energetic drive.', 'usage_count': 161, 'uuid': '9912377c-6be1-4a74-b371-c735594eb638'}]},
  {'objectID': 'c8779618-ee4d-47b7-9584-a7fa2800a5ff', 'subphrases': [{'text': 'Hardworking College Student seeking employment.', 'usage_count': 38919, 'uuid': '1f4008be-08f8-43d5-8686-42aea466216b'}, {'text': 'Bringing forth a motivated attitude and a variety of powerful skills.', 'usage_count': 34099, 'uuid': '791d2ba4-5ce9-4bde-8b74-4cb6f1415c02'}, {'text': 'Adept in various social media platforms and office technology programs.', 'usage_count': 16740, 'uuid': 'd14356d5-1e2e-459d-9c8d-ea8a81b82a61'}, {'text': 'Committed to utilizing my skills to further the mission of a company.', 'usage_count': 33616, 'uuid': '8c2fd795-bd7e-4ec8-8857-a03805486522'}]},
  {'objectID': '1073592e-0356-487e-af79-19676f77203e', 'subphrases': [{'text': 'Dedicated Customer Service Representative dedicated to providing quality care for ultimate customer satisfaction.', 'usage_count': 16554, 'uuid': 'ef8fff11-563e-4f3d-81b3-dbe2952b0189'}, {'text': 'Proven ability to establish and maintain excellent communication and relationships with clients.', 'usage_count': 21959, 'uuid': '286189ff-1c65-4823-a4e7-511f8ef04c28'}, {'text': 'Adept in general accounting and finance transactions.', 'usage_count': 4394, 'uuid': '095eba5c-c957-4b93-89fc-0f6899b5775e'}, {'text': 'Dedicated to identifying customer needs and delivering effective solutions to all problems.', 'usage_count': 12337, 'uuid': '149423fe-9d7a-4c7c-8456-56c5ecfda464'}, {'text': 'Excellent time management skills combined with a superior knowledge of the customer service industry.', 'usage_count': 13608, 'uuid': '905b034e-bd6b-43b7-8fe1-2fab20363539'}, {'text': 'Bilingual, hardworking, and ready to join my next team.', 'usage_count': 15913, 'uuid': 'e864fcf4-abe0-4d81-a574-4662680d987c'}]},
  {'objectID': 'a9917296-d096-4f99-a672-34bdc3ea88db', 'subphrases': [{'text': 'Dedicated Nursing Student with the ability to multitask and work well with others.', 'usage_count': 3329, 'uuid': 'df241eab-2062-4a3f-8e0c-28bfb721275e'}, {'text': 'Patient-care oriented, bringing forth a compassionate and friendly attitude.', 'usage_count': 3722, 'uuid': 'c6a86d7c-698f-4341-8c91-05ac9576eec4'}, {'text': 'Highly organized, and skilled in written and verbal communication.', 'usage_count': 7798, 'uuid': '945f696b-4224-4035-be84-4c485435c26e'}, {'text': 'Committed to utilizing my skills to help others, while working towards the mission of a company.', 'usage_count': 7655, 'uuid': '0b99a00c-6233-43c7-8e0d-46996e104ec3'}, {'text': 'A strong leader who works well under pressure, and exudes positivity.', 'usage_count': 6723, 'uuid': 'f521129c-c0b3-43ea-b320-54900235bca3'}]},
  {'objectID': '2473b6d1-765f-4dfc-896a-1c150c94027a', 'subphrases': [{'text': 'Resourceful and dedicated High School student with excellent analytical skills and a demonstrated commitment to providing great customer service.', 'usage_count': 9974, 'uuid': '24572aa6-bb34-46ff-8746-87e64ede42e6'}, {'text': 'Strong organizational abilities with proven successes managing multiple academic projects and volunteering events.', 'usage_count': 8386, 'uuid': '922ad72d-4a90-49b2-8087-e2a73ae97595'}, {'text': 'Well-rounded and professional team player dedicated to continuing academic pursuits at a collegiate level.', 'usage_count': 6039, 'uuid': '77a10fe0-ec0e-4b6e-9eb9-1bdd174c122b'}]},
  {'objectID': '392b9640-7a14-4270-87c8-e50dee9b34e5', 'subphrases': [{'text': 'Experienced Software Developer adept in bringing forth expertise in design, installation, testing and maintenance of software systems.', 'usage_count': 4688, 'uuid': 'c5cb6199-335f-496e-9bcf-945c5b01d201'}, {'text': 'Proficient in various platforms, languages, and embedded systems.', 'usage_count': 2831, 'uuid': 'c07b7cdf-e5cf-4b18-be9d-529f1b328565'}, {'text': 'Experienced with the latest cutting edge development tools and procedures.', 'usage_count': 2021, 'uuid': '8b1a9d70-0db9-4421-aa76-b39fdb46bd49'}, {'text': '\xa0 Able to effectively self-manage during independent projects, as well as collaborate as part of a productive team.', 'usage_count': 10308, 'uuid': '0362f474-05bf-4e6f-9937-34f5c6d95e82'}]},
  {'objectID': '77df7007-05ba-4204-98ba-294eceea1e6a', 'subphrases': [{'text': 'Dynamic Executive with six years of experience helping organizations reach their full potential.', 'usage_count': 2579, 'uuid': 'cea99c3f-376f-4550-b11f-c0341a11cbcd'}, {'text': 'Adept in making key decisions and working with other professionals to achieve goals and solve problems.', 'usage_count': 5244, 'uuid': '6d18feb5-2e5b-42e4-bc31-5a6911f6507b'}, {'text': 'Experienced in managing employee and community programs, and dedicated to successfully directing business operations.', 'usage_count': 2184, 'uuid': 'a93acf74-462b-43d3-90a6-accb304c10d1'}]},
  {'objectID': 'bebe5334-1f41-473c-8173-fdb76a93cbaf', 'subphrases': [{'text': 'Experienced and self-motivated Office Secretary with ten+ years of industry experience overseeing the main offices of schools.', 'usage_count': 2980, 'uuid': '8827c120-cd65-4cf2-af5d-0b02f0ce0f0d'}, {'text': 'Highly competent communicator skilled in multitasking and effectively communicating with others.', 'usage_count': 4368, 'uuid': '8343613a-fe60-4125-a544-e480806ed9c2'}, {'text': 'Bringing forth a proven track record of successfully managing offices, and helping to lead school professionals to work toward reaching goals.', 'usage_count': 1241, 'uuid': '866cde2a-8e84-4be6-9421-25dfedf03491'}]},
  {'objectID': 'ac7a05ca-647f-40e8-aeac-074134192214', 'subphrases': [{'text': 'Experienced and dedicated Data Analyst with several years of experience identifying efficiencies and problem areas within data streams, while communicating needs for projects.', 'usage_count': 2709, 'uuid': '24110270-0cb1-4e1f-8113-08dc798c46a5'}, {'text': 'Adept at receiving and monitoring data from multiple data streams, including Access, SQL, and Excel data sources.', 'usage_count': 1298, 'uuid': 'f1f1b4a3-2d4b-4568-b0ac-22d9f1ac8fc3'}, {'text': 'Bringing forth the ability to synthesize quantitative information and interact effectively with colleagues and clients.', 'usage_count': 1283, 'uuid': 'ccd5bf33-1823-4005-b85f-5ebb76b06616'}, {'text': 'Proven track record of generating summary documents for senior management for monthly and quarterly audit and compliance reporting.', 'usage_count': 1033, 'uuid': '106de779-f158-41ab-937e-5b29aada011a'}]},
  {'objectID': '274e55d2-6a23-4faa-b619-4b79fca06907', 'subphrases': [{'text': 'Passionate Doctor with extensive experience in Internal Medicine and hospital settings.', 'usage_count': 1190, 'uuid': '6bd38112-5eb5-4bd7-b980-607e6a677dea'}, {'text': 'Adept in properly diagnosing and strategizing for the best treatment plans for patients.', 'usage_count': 713, 'uuid': '4528f58c-d7e7-4b20-b055-25e4ca63d91a'}, {'text': 'Bringing forth an empathetic and professional attitude, committed to providing patients with the best care possible.', 'usage_count': 1788, 'uuid': '3dce9da7-cd06-4610-a41b-b97b17ea9564'}, {'text': 'Experienced in counseling patients on preventative care and positive lifestyle changes.', 'usage_count': 780, 'uuid': '3cd4890c-f341-4733-9c81-b284b58cb848'}, {'text': 'A strong leader who works well under pressure and with other medical professionals.', 'usage_count': 1709, 'uuid': 'ba88d5d2-90e8-41d1-a837-394dba05758f'}]},
  {'objectID': '06354317-66dc-4eaa-91a6-19898ead464c', 'subphrases': [{'text': 'Dedicated Visual Artist with a decorated background in a variety of artistic mediums.', 'usage_count': 1086, 'uuid': 'fe99a95b-1070-4af2-ae5f-1da314bf9799'}, {'text': 'Highly experienced in illustration and animation.', 'usage_count': 756, 'uuid': '46e51144-b7e2-439d-9c5a-bf8629634fb3'}, {'text': 'Adept at working independently and collaboratively on projects, and committed to achieving visual innovation and beauty.', 'usage_count': 2242, 'uuid': '71949f9b-3c20-4803-8e03-4667a2f78082'}, {'text': 'Bringing forth the ability to work well with others and utilize my art skills to visually enhance projects.', 'usage_count': 2238, 'uuid': 'c6c6bbc3-e4c1-4c97-84b3-0549b868ca0d'}]}
 ]
